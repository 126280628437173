import React, { useEffect, useState } from 'react';
import './appointment-calendar.css';
import { Button, Select } from 'antd';
import { BsCalendar } from 'react-icons/bs';
import { calendarEssentialDataInitializer, generateTitle, getAppointment, getEventClass } from './service';
import NewAppointmentModal from './new-appointment.modal';
import AppointmentCalendarComponent from './appointment-calendar.component';
import moment from 'moment';
import { AccessRight } from '../../components/guarded-route/guarded-routes';
import { ApplicationRight } from '../../share/RightList';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const AppointmentCalendarByLocationPage = () => {
  const user = useSelector((state) => state.authenticatedUser);
  const [searchParam] = useSearchParams();

  const [selectedLocation, setSelectedLocation] = useState(undefined);
  const [locationOptions, setLocationOptions] = useState([]);
  const [events, setEvents] = useState(undefined);
  const [providingServices, setProvidingServices] = useState([]);
  const [providers, setProviders] = useState([]);
  const [showNewAppointmentWindow, setShowNewAppointmentWindow] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkIfLocationIsSameAfterReschedule = (oldAppointment, newAppointment) =>
    oldAppointment.location.stationId === newAppointment.location.stationId;

  useEffect(() => {
    if (selectedLocation) {
      setEvents(null);
      setLoading(true);
      (async () => {
        try {
          const appointmentsInTheLocation = await getAppointment([{ location: selectedLocation }]);
          const allData = [];

          if (appointmentsInTheLocation.data?.data.appointments) {
            appointmentsInTheLocation.data.data.appointments.forEach((appointment) => {
              allData.push({
                end: moment(appointment.end).toDate(),
                start: moment(appointment.start).toDate(),
                title: generateTitle(
                  appointment.service,
                  appointment.patient,
                  { staff: appointment.staff },
                  ({ staff }) => `(${staff.firstName} ${staff.lastName} - ${staff.status !== "active" ? 'inactive' : ''})`
                ),
                className: getEventClass(appointment),
                type: 'appointment',
                appointment,
              });
            });
          }
          if (appointmentsInTheLocation.data?.data.appointments[0].location.stationId === selectedLocation) {
            setEvents(allData);
          }
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [selectedLocation]);

  useEffect(() => {
    const locationId = searchParam.get('locationId');

    const defaultLocation = locationId || user.primaryLocation;
    if (defaultLocation) {
      setSelectedLocation(defaultLocation);
    }

    (async () => {
      await calendarEssentialDataInitializer(setLocationOptions, setProviders, setProvidingServices);
      if (!defaultLocation) {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div className="appointment-management">
      <div className="d-flex justify-content-between m-3">
        <div className="d-flex align-items-center">
          <span
            style={{
              padding: 6,
              color: 'white',
              background: '#5284C2',
              borderRadius: 4,
            }}
          >
            <BsCalendar style={{ fontSize: 18, position: 'relative', top: -2 }} />
          </span>
          <span
            style={{
              fontSize: 18,
              marginLeft: 8,
              marginRight: 16,
              fontWeight: 700,
            }}
          >
            Appointment By Location
          </span>
          <Select
            size={'large'}
            value={selectedLocation}
            style={{ width: 350 }}
            onChange={(value) => setSelectedLocation(value)}
            options={locationOptions}
            disabled={loading}
            loading={loading}
            placeholder={loading ? 'Fetching Locations' : 'Select Location'}
          />
        </div>
        <div>
          {AccessRight(ApplicationRight.Appointment_Creation) && (
            <Button type="primary" size={'large'} onClick={() => setShowNewAppointmentWindow(true)}>
              + New Appointment
            </Button>
          )}
        </div>
      </div>
      <AppointmentCalendarComponent
        appointmentEvents={events}
        providers={providers}
        locationOptions={locationOptions}
        providingServices={providingServices}
        eventTitleDifferentiator={({ staff, location }) =>
          `(${staff.firstName} ${staff.lastName} - ${staff.status !== "active" ? 'inactive' : ''}  @ ${location.name})`
        }
        checkAfterReschedule={checkIfLocationIsSameAfterReschedule}
        locationId={selectedLocation}
      />
      {showNewAppointmentWindow && (
        <NewAppointmentModal
          show={showNewAppointmentWindow}
          locations={locationOptions}
          defaultLocation={selectedLocation}
          services={providingServices}
          onFinish={() => setShowNewAppointmentWindow(false)}
        />
      )}
    </div>
  );
};

export default AppointmentCalendarByLocationPage;
