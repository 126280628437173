import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  Alert,
  Avatar,
  Button,
  Checkbox as AntCheckbox,
  Col,
  Descriptions,
  Dropdown,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { BiChevronDown } from 'react-icons/bi';
import { Checkbox } from '@mui/material';
import { getLocation, getStaffSchedules } from '../appointments/service';
import { DownOutlined } from '@ant-design/icons';
import { searchStaff } from './staff-search.service';
import { submitSchedule } from './staff.service';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import dayjs from 'dayjs';
import DatePickerWithMoment from '../../components/date/DatePickerWithMoment';
import { useSelector } from 'react-redux';
import { isInSameTimezone } from '../../share/TimezoneList';
import { RiTimeZoneLine } from 'react-icons/ri';
import { TbCalendarX, TbClockX, TbLockCog, TbLockOpen } from 'react-icons/tb';
import {ApplicationRight} from "../../share/RightList";

const StaffSchedulePage = () => {
  const userTimezone = useSelector((state) => state.timezone);
  const weekDate = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [newSchedules, setNewSchedules] = useState([]);
  const [existingSchedules, setExistingSchedules] = useState([]);
  const [locations, setLocations] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [staffId, setStaffId] = useState('');
  const [searchParam] = useSearchParams();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const timeRange = [
    { value: '9to20', label: '9am - 8pm' },
    { value: '9to17', label: '9am - 5pm' },
    { value: '10to14', label: '10am - 2pm' },
    { value: '11to17', label: '11am - 5pm' },
    { value: '8to18', label: '8am - 6pm' },
  ];

  const CustomDatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <Button type="text" onClick={onClick} ref={ref} style={{ fontSize: 16 }}>
      {`at the week of: ${moment(value).startOf('week').format('MMMM DD, yyyy')} - ${moment(value)
        .endOf('week')
        .format('MMMM DD, yyyy')}`}
      <BiChevronDown style={{ marginLeft: 5, position: 'relative', top: -2 }} />
    </Button>
  ));

  const isInputBad = (changedAndCheckedDays) => {
    if (!!changedAndCheckedDays.length) {
      return !!changedAndCheckedDays.find((s) => s.checked && (!s.location || !s.start || !s.end));
    }

    return false;
  };

  const createSchedule = async () => {
    setSubmitting(true);
    try {
      const payload = newSchedules
        .filter((ns) => moment(ns.date).isSameOrAfter(moment(), 'date'))
        .map((ns) => {
          const timezone = locations.find((l) => l.stationId === ns.location)?.timezone;

          let start = null;
          let end = null;
          let location;

          if (ns.checked) {
            location = ns.location;
          } else {
            location = null;
          }

          if (ns.checked && ns.start && ns.end) {
            const startHour = ns.start.hour();
            const endHour = ns.end.hour();
            const startMinute = ns.start.minute();
            const endMinute = ns.end.minute();

            start = moment.tz(ns.date, 'YYYY-MM-DD', timezone).clone();
            end = moment.tz(ns.date, 'YYYY-MM-DD', timezone).clone();
            start.hour(startHour).minute(startMinute).second(0).millisecond(0);
            end.hour(endHour).minute(endMinute).second(0).millisecond(0);
          }

          return {
            date: ns.date,
            location,
            start,
            end,
            operation: ns.checked ? 'upsert' : 'remove',
            appointments: ns.bookedAppointments,
            timezone,
          };
        });
      await submitSchedule(staffId, payload);
      const week = `${newSchedules[0].date} - ${newSchedules[6].date}`;
      const staff = staffs.find((s) => s.staffId === staffId);
      notification.success({
        message: `New schedule has been set!`,
        description: (
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="Staff">{`${staff.firstName} ${staff.lastName} ${staff.status !== "active" ? '(inactive)' : ''} `}</Descriptions.Item>
            <Descriptions.Item label="Week">{week}</Descriptions.Item>
          </Descriptions>
        ),
        duration: 0,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitting(false);
    }
  };

  const setNewScheduleTime = (index, start, end, date, timezone, existingBookedAppointments) => {
    let newSchedulesArray;

    if (!!start && !!end) {
      const newStart = moment.tz(date, 'YYYY-MM-DD', timezone).clone();
      const newEnd = moment.tz(date, 'YYYY-MM-DD', timezone).clone();
      newStart.hour(start.hour()).minute(start.minute()).second(0).millisecond(0);
      newEnd.hour(end.hour()).minute(end.minute()).second(0).millisecond(0);
      newSchedules[index].start = newStart;
      newSchedules[index].end = newEnd;
      newSchedulesArray = [...newSchedules];
      // if newstart is after earliest existing appointment's start or newend is before latest existing appointment's end, set error to true
      if (existingBookedAppointments.length) {
        const earliestAppointment = existingBookedAppointments.reduce((earliest, current) => {
          return moment(current.start).isBefore(moment(earliest.start)) ? current : earliest;
        });
        const latestAppointment = existingBookedAppointments.reduce((latest, current) => {
          return moment(current.end).isAfter(moment(latest.end)) ? current : latest;
        });
        newSchedules[index].timeError =
          moment(newStart).isAfter(moment(earliestAppointment.start)) ||
          moment(newEnd).isBefore(moment(latestAppointment.end));
      }
    } else {
      newSchedules[index].start = null;
      newSchedules[index].end = null;
      newSchedules[index].timeError = false;
      newSchedulesArray = [...newSchedules];
    }
    setNewSchedules(newSchedulesArray);
  };

  useEffect(() => {
    if (staffId) {
      (async () => {
        const staffSchedule = await getStaffSchedules({ staff: staffId, timezone: userTimezone });
        setExistingSchedules(staffSchedule.data.data);
      })();
    }
  }, [staffId]);

  useEffect(() => {
    if (selectedDate && staffId) {
      const startOfWeek = moment(selectedDate).startOf('week');
      const endOfWeek = moment(selectedDate).endOf('week');

      const theWeek = [];

      const hasScheduleInThisWeek = existingSchedules.find((es) => startOfWeek.isSame(moment(es.date), 'week'));

      for (let date = startOfWeek; date <= endOfWeek; date = date.clone().add(1, 'd')) {
        const existingBookedAppointments =
          existingSchedules
            .find((es) => date.isSame(moment(es.date), 'date'))
            ?.appointments.filter((a) => a.status === 'Booked') || [];

        const location = existingSchedules.find((es) => date.isSame(moment(es.date), 'date'))?.location;
        const start = existingSchedules.find((es) => date.isSame(moment(es.date), 'date'))?.start;
        const end = existingSchedules.find((es) => date.isSame(moment(es.date), 'date'))?.end;
        const timezone = locations.find((l) => l.stationId === location)?.timezone;

        let checked = false;

        if (hasScheduleInThisWeek) {
          if (start && end && location) {
            if (date.isSameOrAfter(moment(), 'date')) {
              checked = true;
            } else {
              checked = !(date.day() === 0 || date.day() === 6);
            }
          }
        } else {
          checked = !(date.day() === 0 || date.day() === 6);
        }

        theWeek.push({
          date: date.format('YYYY-MM-DD'),
          location,
          start: start ? moment.tz(start, timezone) : undefined,
          end: end ? moment.tz(end, timezone) : undefined,
          checked: checked,
          bookedAppointments: existingBookedAppointments,
          isPastDate: date.isBefore(moment(), 'date'),
          hasAppointment: existingBookedAppointments.length > 0,
          inDifferentTimezone: !isInSameTimezone(timezone, staffs.find((s) => s.staffId === staffId).timezone),
        });
      }

      setNewSchedules([...theWeek]);
    }
  }, [selectedDate, existingSchedules]);

  useEffect(() => {
    const changedDays = [];
    const todayOrFutureDaysNewSchedule = newSchedules.filter((s) => moment(s.date).isSameOrAfter(moment(), 'date'));

    todayOrFutureDaysNewSchedule.forEach((s) => {
      const existingSchedule = existingSchedules.find((es) => s.date === es.date);
      if (existingSchedule) {
        if (existingSchedule.start && existingSchedule.end) {
          if (
            !s.checked ||
            !moment(s.start).isSame(moment(existingSchedule.start)) ||
            !moment(s.end).isSame(moment(existingSchedule.end)) ||
            s.location !== existingSchedule.location
          ) {
            changedDays.push(s);
          }
        } else {
          if (s.checked) {
            changedDays.push(s);
          }
        }
      } else {
        if (s.checked) {
          changedDays.push(s);
        }
      }
    });
    if (changedDays.length) {
      const invalidInput = isInputBad(changedDays.filter((s) => s.checked));
      const hasConflict = !!changedDays.find((s) => s.timeError);

      setDisableSubmit(invalidInput || hasConflict);
    } else {
      setDisableSubmit(true);
    }
  }, [newSchedules]);

  useEffect(() => {
    (async () => {
      const locationInformation = await getLocation();

      if (locationInformation.data.data) {
        setLocations(locationInformation.data.data);
      }
    })();
    (async () => {
      searchStaff('right', ApplicationRight.Schedule_And_Appointment_Acceptance).then((response) => {
        if (response.status === 200) {
          setStaffs(
            response.data.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName))
          );
          const passingStaff = searchParam.get('staffId');
          setStaffId(passingStaff);
        } else {
          alert(response.message);
        }
      });
    })();
  }, []);

  return (
    <>
      <div className="appointment-management" style={{ paddingInline: 16, paddingBlock: 20 }}>
        <Typography.Title level={2}>Staff Schedule</Typography.Title>
        {!(staffs && staffs.length) && (
          <Spin tip="Loading...">
            <Alert message="Loading" description="Loading Essential Data" type="info" />
          </Spin>
        )}
        {!!(staffs && staffs.length) && (
          <>
            <Row className="align-items-center justify-content-between">
              <Col>
                <Row className="align-items-center">
                  <Col>
                    <Select
                      placeholder="Select A Staff"
                      size="large"
                      style={{ minWidth: 255 }}
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      onChange={setStaffId}
                      optionLabelProp="label"
                      value={staffId}
                    >
                      {(staffs || []).map((s, i) => (
                        <Select.Option key={i} value={s.staffId} label={`${s.firstName} ${s.lastName} ${(s.status == "inactive" || !s.status) ? '(inactive)' : ''}`}>
                          <div className="d-flex align-items-center">
                            <Avatar size="30" src={s.coverImage} style={{ marginRight: 10 }} />
                            <div>{`${s.firstName} ${s.lastName} ${(s.status == "inactive" || !s.status) ? '(inactive)' : ''}`}</div>
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col>
                    {staffId && (
                      <DatePicker
                        selected={selectedDate}
                        minDate={moment().toDate()}
                        disabledKeyboardNavigation
                        onChange={(date) => setSelectedDate(moment(date).toDate())}
                        customInput={<CustomDatePickerInput />}
                        dayClassName={(date) =>
                          dayjs(selectedDate.toDateString()).isSame(date.toDateString(), 'week')
                            ? 'react-datepicker__day--same-week'
                            : ''
                        }
                      />
                    )}
                  </Col>
                  <Col>
                    {staffId &&
                      (staffs.find((s) => s.staffId === staffId).timezone ? (
                        <Typography.Text type="secondary">
                          This staff's primary timezone is
                          <Typography.Text strong style={{ marginLeft: 4 }}>
                            {staffs.find((s) => s.staffId === staffId).timezone}
                          </Typography.Text>
                        </Typography.Text>
                      ) : (
                        <Alert
                          type={'error'}
                          message="STOP! Set this staff's primary location before making any schedule."
                        />
                      ))}
                  </Col>
                </Row>
              </Col>
            </Row>
            {!!staffId && (
              <>
                <div className="mt-3">
                  <Row className="mb-2">
                    <Col span={5}></Col>
                    <Col span={6} className={'d-flex justify-content-center align-items-center'}>
                      <Dropdown
                        menu={{
                          items: timeRange.map((l) => ({ key: l.value, label: l.label })),
                          onClick: ({ key }) => {
                            const numbers = key.split('to').map((numStr) => parseInt(numStr));
                            newSchedules.forEach((s) => {
                              if (!s.isPastDate && s.checked) {
                                const timezone = locations.find((l) => l.stationId === s.location)?.timezone;

                                const start = moment.tz(s.date, 'YYYY-MM-DD', timezone).clone();
                                const end = moment.tz(s.date, 'YYYY-MM-DD', timezone).clone();
                                start.hour(numbers[0]).minute(0).second(0).millisecond(0);
                                end.hour(numbers[1]).minute(0).second(0).millisecond(0);

                                s.start = start;
                                s.end = end;

                                if (s.bookedAppointments.length) {
                                  const earliestAppointment = s.bookedAppointments.reduce((earliest, current) => {
                                    return moment(current.start).isBefore(moment(earliest.start)) ? current : earliest;
                                  });
                                  const latestAppointment = s.bookedAppointments.reduce((latest, current) => {
                                    return moment(current.end).isAfter(moment(latest.end)) ? current : latest;
                                  });
                                  s.timeError =
                                    moment(start).isAfter(moment(earliestAppointment.start)) ||
                                    moment(end).isBefore(moment(latestAppointment.end));
                                }
                              }
                            });
                            setNewSchedules([...newSchedules]);
                          },
                        }}
                      >
                        <a onClick={(e) => e.preventDefault()}>
                          <Space>
                            Set all selected dates' time to
                            <DownOutlined style={{ position: 'relative', top: -2 }} />
                          </Space>
                        </a>
                      </Dropdown>
                    </Col>
                    <Col span={7} className="d-flex justify-content-center align-items-center">
                      <Dropdown
                        menu={{
                          items: locations.map((l) => ({ key: l.stationId, label: `${l.name}, ${l.address.state}` })),
                          onClick: ({ key }) => {
                            newSchedules.forEach((s) => {
                              if (!s.isPastDate && s.checked && !s.hasAppointment) {
                                s.location = key;
                                const timezone = locations.find((l) => l.stationId === key)?.timezone;
                                s.inDifferentTimezone = !isInSameTimezone(
                                  timezone,
                                  staffs.find((s) => s.staffId === staffId).timezone
                                );
                                if (s.start && s.end) {
                                  const start = moment.tz(s.date, 'YYYY-MM-DD', timezone).clone();
                                  const end = moment.tz(s.date, 'YYYY-MM-DD', timezone).clone();
                                  start.hour(s.start.hour()).minute(s.start.minute()).second(0).millisecond(0);
                                  end.hour(s.end.hour()).minute(s.end.minute()).second(0).millisecond(0);
                                  s.start = start;
                                  s.end = end;
                                }
                              }
                            });
                            setNewSchedules([...newSchedules]);
                          },
                        }}
                      >
                        <a onClick={(e) => e.preventDefault()}>
                          <Space>
                            Set all selected dates' location to
                            <DownOutlined style={{ position: 'relative', top: -2 }} />
                          </Space>
                        </a>
                      </Dropdown>
                    </Col>
                  </Row>
                  {newSchedules.map((schedule, i) => {
                    const timezone = locations.find((l) => l.stationId === schedule.location)?.timezone;

                    return (
                      <Typography.Paragraph key={i}>
                        <Row>
                          <Col className="d-flex justify-content-start align-items-center" span={3}>
                            <Checkbox
                              checked={schedule.checked}
                              sx={{
                                '& .MuiSvgIcon-root': { fontSize: 28 },
                              }}
                              onChange={(event, checked) => {
                                schedule.checked = checked;
                                setNewSchedules([...newSchedules]);
                              }}
                              disabled={schedule.isPastDate || schedule.hasAppointment}
                            />
                            <Typography.Title
                              level={5}
                              disabled={schedule.isPastDate}
                              style={{ marginBottom: '0', textDecoration: schedule.isPastDate ? 'line-through' : '' }}
                            >
                              {weekDate[i]}
                            </Typography.Title>
                          </Col>
                          <Col className="d-flex justify-content-center align-items-center" span={2}>
                            <Typography.Text
                              type={'secondary'}
                              delete={schedule.isPastDate}
                              disabled={schedule.isPastDate}
                            >
                              {schedule.date}
                            </Typography.Text>
                          </Col>
                          <Col className="d-flex justify-content-center align-items-center" span={6}>
                            <DatePickerWithMoment.RangePicker
                              picker="time"
                              onChange={(value) =>
                                setNewScheduleTime(
                                  i,
                                  value[0],
                                  value[1],
                                  schedule.date,
                                  timezone,
                                  schedule.bookedAppointments
                                )
                              }
                              order={true}
                              value={
                                schedule.start && schedule.end
                                  ? [moment.tz(schedule.start, timezone), moment.tz(schedule.end, timezone)]
                                  : undefined
                              }
                              format="h:mm a"
                              size="large"
                              disabled={!schedule.checked || schedule.isPastDate}
                              status={schedule.timeError ? 'error' : ''}
                              minuteStep={30}
                              needConfirm={false}
                            />
                          </Col>
                          <Col className="d-flex justify-content-center align-items-center" span={7}>
                            <Select
                              placeholder="Please select location"
                              size="large"
                              value={schedule.location}
                              style={{ width: '100%' }}
                              onChange={(value) => {
                                schedule.location = value;
                                const nextTimezone = locations.find((l) => l.stationId === value)?.timezone;
                                schedule.inDifferentTimezone = !isInSameTimezone(
                                  nextTimezone,
                                  staffs.find((s) => s.staffId === staffId).timezone
                                );
                                if (schedule.start && schedule.end) {
                                  const start = moment.tz(schedule.date, 'YYYY-MM-DD', nextTimezone).clone();
                                  const end = moment.tz(schedule.date, 'YYYY-MM-DD', nextTimezone).clone();
                                  start
                                    .hour(schedule.start.hour())
                                    .minute(schedule.start.minute())
                                    .second(0)
                                    .millisecond(0);
                                  end.hour(schedule.end.hour()).minute(schedule.end.minute()).second(0).millisecond(0);
                                  schedule.start = start;
                                  schedule.end = end;
                                }

                                setNewSchedules([...newSchedules]);
                              }}
                              options={locations.map((location) => ({
                                value: location.stationId,
                                label: `${location.name}, ${location.address.state}`,
                              }))}
                              disabled={(() => {
                                if (schedule.locationChangeAffirmative) {
                                  return false;
                                } else {
                                  return !schedule.checked || schedule.isPastDate || schedule.hasAppointment;
                                }
                              })()}
                            />
                          </Col>
                          <Col className="d-flex align-items-center ms-2">
                            <Space>
                              {schedule.inDifferentTimezone && (
                                <Tooltip
                                  title={
                                    <Typography.Text type={'warning'}>
                                      This location's timezone{' '}
                                      <Typography.Text strong type={'warning'}>
                                        {timezone}
                                      </Typography.Text>{' '}
                                      is different from staff's primary location timezone
                                    </Typography.Text>
                                  }
                                >
                                  <RiTimeZoneLine color={'orange'} size={18} />
                                </Tooltip>
                              )}
                              {schedule.isPastDate && (
                                <Tooltip title={<Typography.Text type={'danger'}>Past Date</Typography.Text>}>
                                  <TbCalendarX color={'#ff1800'} size={18} />
                                </Tooltip>
                              )}
                              {schedule.hasAppointment && (
                                <Tooltip
                                  overlayInnerStyle={{ width: 300 }}
                                  title={
                                    <div>
                                      <Typography.Link
                                        target={'_blank'}
                                        href={`/appointment-management/by-provider?staffId=${staffId}&date=${schedule.date}`}
                                      >
                                        OPEN APPOINTMENTS
                                      </Typography.Link>{' '}
                                      are found on this day for this staff. Thus schedule updates are restricted to time
                                      changes unless the checkbox is selected.{' '}
                                      <Typography.Text type={'warning'}>
                                        It's essential to understand that the system <b>will not</b> automatically
                                        adjust the location of these open appointments; you <b>must</b> manually
                                        reschedule each one to ensure accuracy.
                                      </Typography.Text>
                                      <hr />
                                      <AntCheckbox
                                        checked={schedule.locationChangeAffirmative}
                                        onChange={(e) => {
                                          schedule.locationChangeAffirmative = e.target.checked;
                                          if (!e.target.checked) {
                                            schedule.location = existingSchedules.find(
                                              (es) => schedule.date === es.date
                                            ).location;
                                          }
                                          setNewSchedules([...newSchedules]);
                                        }}
                                      >
                                        <span style={{ color: 'white' }}>Change Location Of This Day</span>
                                      </AntCheckbox>
                                    </div>
                                  }
                                >
                                  {schedule.locationChangeAffirmative ? (
                                    <TbLockOpen color={'green'} size={18} />
                                  ) : (
                                    <TbLockCog color={'orange'} size={18} />
                                  )}
                                </Tooltip>
                              )}
                              {!schedule.locationChangeAffirmative && schedule.timeError && (
                                <Tooltip
                                  title={
                                    <Typography.Text type={'danger'}>
                                      There are open appointments on this day that start before the new start time or
                                      end after the new end time. Please adjust the time to avoid conflict.
                                    </Typography.Text>
                                  }
                                >
                                  <TbClockX color={'#ff1800'} size={18} />
                                </Tooltip>
                              )}
                            </Space>
                          </Col>
                        </Row>
                      </Typography.Paragraph>
                    );
                  })}
                </div>
                <Row className="justify-content-end align-items-center">
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    onClick={createSchedule}
                    loading={submitting}
                    disabled={disableSubmit}
                  >
                    Submit
                  </Button>
                </Row>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default StaffSchedulePage;
