import bioApi from '../../api/bioApi';
import {
  LOCATION_LIST_URL,
  PATIENT_CREATE_URL,
  PATIENT_SEARCH_URL,
  PROSPECT_PATIENT_COMPARE_URL,
  PROSPECT_PATIENT_SEARCH_URL,
} from '../../api/URLs';

export const listAllLocations = () => {
  return bioApi.post(LOCATION_LIST_URL, {
    limit: 1000,
  });
};

export const searchPatients = (option, query, location, withProspectPatient = false) => {
  const url = PATIENT_SEARCH_URL.replace(':option', option).replace(':query', query || 'all');
  if (location && location !== '') {
    return bioApi.get(url, { params: { location: location } });
  } else {
    return bioApi.get(url, { params: { withProspectPatient } });
  }
};

export const getPropsectPatientOptions = (searchContent = '') => {
  return bioApi.post(PROSPECT_PATIENT_SEARCH_URL, {
    content: searchContent,
  });
};

export const compareProspectPatient = (data) => {
  return bioApi.post(PROSPECT_PATIENT_COMPARE_URL, data);
};
