import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  notification,
  Row,
  Space,
  Typography,
} from 'antd';
import {
  calculateAge,
  createMedicalNote,
  getMedicalNotes,
  getSymptomsAndGoals,
  updateSymptomsAndGoals,
} from '../service';
import React, { useEffect, useState } from 'react';
import { updateCurrentPatient } from '../../../stores/current-patient.store';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import MedicalNoteDisplay from './components/medical-note.display';
import convertLabResultTableData from './utils/convert-lab-result-table-data';
import DataTable from '../../../components/data-table/data-table.component';
import { ExportOutlined, ForkOutlined } from '@ant-design/icons';

const MedicalNotesPage = () => {
  const dispatch = useDispatch();
  const currentPatient = useSelector((state) => state.currentPatient);
  const currentLabResult = useSelector((state) => state.currentLabResult);
  const [gender, setGender] = useState('');
  const [form] = Form.useForm();
  const [expanded, setExpanded] = useState('1');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkingNote, setCheckingNote] = useState(null);
  const [availableGoals, setAvailableGoals] = React.useState([]);
  const [medicalNotes, setMedicalNotes] = useState([]);
  const [medicalNotesDraft, setMedicalNotesDraft] = useState([]);
  const [subtitle, setSubtitle] = useState('');
  const [sourceId, setSourceId] = useState(null);
  const [medicalNotesItem, setMedicalNotesItem] = useState(null);
  const [medicalNotesLoading, setMedicalNotesLoading] = useState(false);

  const [labResultTableData, setLabResultTableData] = useState([]);
  const [labResultTableSetting, setLabResultTableSetting] = useState(null);

  const notesPrefill = `History, labs and recommendations reviewed with patient. Risks and benefits of HRT as well as therapy options and expectations were discussed with patient. Discussed staying up-to-date with applicable screening tests including breast, prostate, cervical, colorectal, and lung cancers as recommended by the U.S. Preventive Services Task Force (USPSTF).`;
  const onFinish = async (values) => {
    try {
      let note_id = null;
      if(medicalNotesItem && medicalNotesItem.status === "draft") {
        note_id = medicalNotesItem._id;
      }
      const status = "submitted";
      const newSymptomsAndGoals = values.health_concern;
      const oldSymptomsAndGoals = currentPatient.symptomsAndGoals || [];
      if (!arraysAreTheSame(newSymptomsAndGoals, oldSymptomsAndGoals)) {
        await updateSymptomsAndGoals(currentPatient.patientId, newSymptomsAndGoals);
        dispatch(updateCurrentPatient({ key: 'symptomsAndGoals', value: newSymptomsAndGoals }));
      }
      await createMedicalNote(currentPatient.patientId, values, sourceId, status, note_id);
      await fetchData(currentPatient.patientId);
      form.resetFields();
      setMedicalNotesItem(null);
      notification.success({ message: 'successfully created medical note', duration: 5 });
      setExpanded('3');
    } catch (e) {
      notification.error({ message: 'unable to create medical note!', duration: 5 });
    }
  };

  const handleDraft = async () => {
    const values = form.getFieldsValue();
    try {
      let note_id = null;
      if(medicalNotesItem && medicalNotesItem.status === "draft") {
        note_id = medicalNotesItem._id;
      }
      const status = "draft";
      const newSymptomsAndGoals = values.health_concern;
      const oldSymptomsAndGoals = currentPatient.symptomsAndGoals || [];
      if (!arraysAreTheSame(newSymptomsAndGoals, oldSymptomsAndGoals)) {
        await updateSymptomsAndGoals(currentPatient.patientId, newSymptomsAndGoals);
        dispatch(updateCurrentPatient({ key: 'symptomsAndGoals', value: newSymptomsAndGoals }));
      }
      await createMedicalNote(currentPatient.patientId, values, sourceId, status, note_id);
      await fetchData(currentPatient.patientId);
      form.resetFields();
      setMedicalNotesItem(null);
      notification.info({ message: medicalNotesItem && medicalNotesItem.status === "draft" ? "Saved Draft medical note" : "Draft medical note", duration: 5 });
      setExpanded('2');
    } catch (e) {
      notification.error({ message: 'unable to draft medical note!', duration: 5 });
    }
  }

  const fetchSymptomsAndGoals = async () => {
    const goals = await getSymptomsAndGoals(currentPatient.patientId);
    if (goals.data) {
      const data = goals.data.data.map((sag) => ({
        label: sag.name,
      }));
      setAvailableGoals(data);
    }
  };

  const expandChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const arraysAreTheSame = (arr1, arr2) => {
    return arr1.length === arr2.length && arr1.every((item) => arr2.includes(item));
  };

  const fetchData = async (patientId) => {
    const response = await getMedicalNotes(patientId);
    if(response.data) {
      setMedicalNotesDraft(response.data.filter((item) => item.status === "draft"));
      setMedicalNotes(response.data.filter((item) => item.status !== "draft"));
    }
  }

  useEffect(() => {
    if (currentPatient && !currentPatient.profile.nonBinaryGender) {
      const theGender = currentPatient.profile.gender.charAt(0).toUpperCase() + currentPatient.profile.gender.slice(1);
      setGender(theGender);

      (async () => {
        setMedicalNotesLoading(true);
        await fetchData(currentPatient.patientId);
        setMedicalNotesLoading(false);
      })();

      const allLabResult = convertLabResultTableData(currentLabResult, currentPatient);

      const biomarkerGroupMapping = (labRecord, index) => {
        const obj = {
          _id: index,
          biomarker: labRecord.biomarker,
          optimalRange: labRecord.optimalRange,
        };
        if (index === 0) {
          obj.type = labRecord.type;
        } else {
          obj.type = '';
        }
        labRecord.results.forEach((r, i) => {
          obj['result' + i] = r;
        });

        return obj;
      };

      const hormonesBiomarkers = allLabResult
        .filter((ltd) => [ltd.type].includes('Hormones'))
        .map(biomarkerGroupMapping);
      const thyroidBiomarkers = allLabResult
        .filter((ltd) => [ltd.type].includes('Thyroid Health'))
        .map(biomarkerGroupMapping);
      const cardiBiomarkers = allLabResult
        .filter((ltd) => [ltd.type].includes('Cardiovascular & Inflammation'))
        .map(biomarkerGroupMapping);
      const generalWellnessBiomarkers = allLabResult
        .filter((ltd) => [ltd.type].includes('General Wellness'))
        .map(biomarkerGroupMapping);

      const biomarkerRowSpan = {
        Hormones: hormonesBiomarkers.length,
        'Thyroid Health': thyroidBiomarkers.length,
        'Cardiovascular & Inflammation': cardiBiomarkers.length,
        'General Wellness': generalWellnessBiomarkers.length,
      };

      const tableData = [...hormonesBiomarkers, ...thyroidBiomarkers, ...cardiBiomarkers, ...generalWellnessBiomarkers];

      const currentLabReportDate =
        currentPatient.allLabResults.find((plr) => plr._id === currentPatient.currentLabReportId)?.collectionDate || '';

      const tableSetting = {
        appendable: false,
        removable: false,
        bordered: true,
        size: 'middle',
        pagination: false,
        scroll: { x: document.getElementById('app-content').getBoundingClientRect().width - 64 - 32 },
        columns: [
          {
            title: 'Type',
            dataIndex: 'type',
            width: '15%',
            fixed: 'left',
            onCell: (record) => ({
              rowSpan: biomarkerRowSpan[record.type] || 0,
            }),
            render: (text) => (
              <Typography.Text underline strong>
                {text}
              </Typography.Text>
            ),
          },
          {
            title: 'BioMarker',
            dataIndex: 'biomarker',
            fixed: 'left',
            width: '20%',
          },
          {
            title: (
              <div>
                Lab Result
                <div style={{ opacity: 0.5, fontWeight: 'normal' }}>{currentLabReportDate}</div>
              </div>
            ),
            dataIndex: ['result0', 'value'],
            fixed: 'left',
            render: (text, record) => (
              <span style={{ color: record.result0.isAbnormal ? 'red' : 'black' }}>{text}</span>
            ),
            width: '10%',
          },
        ],
      };

      let adjustRecentResultWidth = false;
      if (allLabResult.length) {
        tableSetting.scroll.x = tableSetting.scroll.x + 128 * allLabResult[0].results.length - 320;
        allLabResult[0].results.forEach((r, i) => {
          if (i === 0) {
            if (allLabResult[0].results.length < 2) {
              adjustRecentResultWidth = true;
            }
          } else {
            tableSetting.columns.push({
              title: (
                <div>
                  Lab Result
                  <div style={{ opacity: 0.5, fontWeight: 'normal' }}>{r.date}</div>
                </div>
              ),
              dataIndex: ['result' + i, 'value'],
              className: 'drag-to-scroll',
              width: '10%',
              render: (text, record) => (
                <div style={{ color: record['result' + i].isAbnormal ? 'red' : 'black' }}>{text}</div>
              ),
            });
          }
        });
      } else {
        adjustRecentResultWidth = true;
      }

      if (adjustRecentResultWidth) {
        delete tableSetting.scroll;
      }

      tableSetting.columns.push({
        title: 'Optimal Range',
        dataIndex: 'optimalRange',
        fixed: 'right',
        width: '13%',
      });

      setLabResultTableData(tableData);
      setLabResultTableSetting(tableSetting);

      fetchSymptomsAndGoals();
    }
  }, [currentPatient]);

  return (
    <div>
      <Accordion expanded={expanded === '1'} onChange={expandChange('1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Space style={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography.Title level={5}>Medical Intake Form</Typography.Title>
            <Typography.Text type={'secondary'}>{subtitle}</Typography.Text>
          </Space>
        </AccordionSummary>
        <AccordionDetails>
          <Form
            form={form}
            name="medical-form"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            style={{ maxWidth: '100%' }}
            initialValues={{
              age: calculateAge(currentPatient.profile.dob),
              notes: notesPrefill,
              lab_review: currentLabResult ? currentLabResult.filter((r) => r.isAbnormal) : [],
              health_concern: currentPatient ? currentPatient.symptomsAndGoals : [],
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Card title="Chief Complaint(s):" type="inner">
              <Card.Grid style={{ width: '50%' }} hoverable={false}>
                <Form.Item label="HPI">
                  <Form.Item name={['age']} noStyle>
                    <InputNumber min={1} max={150} style={{ marginRight: '4px', width: '60px' }} />
                  </Form.Item>
                  years old {gender}
                  <br />
                  <Form.Item name={['complains']} noStyle>
                    <Input.TextArea rows={6} placeholder="Complains" />
                  </Form.Item>
                </Form.Item>
              </Card.Grid>
              <Card.Grid style={{ width: '50%' }} hoverable={false}>
                <Form.Item
                  labelCol={{ span: 12 }}
                  label="Labs being reviewed were drawn on"
                  name={['lab_collection_date']}
                >
                  <Input />
                </Form.Item>

                <Form.Item labelCol={{ span: 12 }} label="Labs were completed">
                  {gender === 'Male' ? (
                    <>
                      <Form.Item noStyle name={['day_number']}>
                        <InputNumber style={{ width: 60, marginRight: 4 }} />
                      </Form.Item>
                      days post injection
                    </>
                  ) : (
                    <>
                      on Day
                      <Form.Item name={['day_number']} noStyle>
                        <InputNumber style={{ width: 60, marginInline: 4 }} />
                      </Form.Item>
                      of cycle
                    </>
                  )}
                </Form.Item>
              </Card.Grid>
            </Card>
            <Card title="General Health" type="inner" style={{ marginTop: 8 }}>
              <Row gutter={8}>
                <Col span={8}>
                  <Card title="Sleep" bordered={false}>
                    <Form.Item name={['sleep']} noStyle>
                      <Input.TextArea rows={3} placeholder="Sleep" />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="Energy" bordered={false}>
                    <Form.Item name={['energy']} noStyle>
                      <Input.TextArea rows={3} placeholder="Energy" />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="Stress" bordered={false}>
                    <Form.Item name={['stress']} noStyle>
                      <Input.TextArea rows={3} placeholder="Stress" />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="Libido" bordered={false}>
                    <Form.Item name={['libido']} noStyle>
                      <Input.TextArea rows={3} placeholder="Libido" />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="Exercise" bordered={false}>
                    <Form.Item name={['exercise']} noStyle>
                      <Input.TextArea rows={3} placeholder="Exercise" />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="Diet" bordered={false}>
                    <Form.Item name={['diet']} noStyle>
                      <Input.TextArea rows={3} placeholder="Diet" />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="Gut health" bordered={false}>
                    <Form.Item name={['gut_health']} noStyle>
                      <Input.TextArea rows={3} placeholder="Gut health" />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="Cognitive function" bordered={false}>
                    <Form.Item name={['cognitive_function']} noStyle>
                      <Input.TextArea rows={3} placeholder="Cognitive function" />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="Weight management" bordered={false}>
                    <Form.Item name={['weight_management']} noStyle>
                      <Input.TextArea rows={3} placeholder="Weight management" />
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
            </Card>
            <Card title={`Sexual Health (${gender})`} style={{ marginTop: 8 }} type="inner">
              {gender === 'Male' ? (
                <>
                  <Form.Item label={'Hx of TRT/Anabolic steroid use'} name={['steroid_use']}>
                    <Input.TextArea rows={2} />
                  </Form.Item>
                  <Form.Item label={'ED'} name={['ed']}>
                    <Input.TextArea rows={2} />
                  </Form.Item>
                  <Form.Item label={'STI'} name={['sti']}>
                    <Input.TextArea rows={2} />
                  </Form.Item>
                  <Row>
                    <Col span={12}>
                      <Form.Item labelCol={{ span: 12 }} label={'Last Colonoscopy'} name={['last_colonoscopy']}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item labelCol={{ span: 12 }} label={'Last Prostate Exam'} name={['last_prostate_exam']}>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Form.Item label={'GP'} name={['gp']}>
                    <Input.TextArea rows={2} />
                  </Form.Item>
                  <Form.Item label={'Menstrual Cycle'} name={['menstrual_cycle']}>
                    <Input.TextArea rows={2} />
                  </Form.Item>
                  <Form.Item label={'LMP'} name={['lmp']}>
                    <Input />
                  </Form.Item>
                  <Form.Item label={'PMS'} name={['pms']}>
                    <Input.TextArea rows={2} />
                  </Form.Item>
                  <Form.Item label={'Birth Control'} name={['birth_control']}>
                    <Input.TextArea rows={2} />
                  </Form.Item>
                  <Form.Item label={'HX of Fibroids'} name={['hx_of_fibroids']}>
                    <Input.TextArea rows={2} />
                  </Form.Item>
                  <Form.Item label={'HX of Endometriosis'} name={['hx_of_endometriosis']}>
                    <Input.TextArea rows={2} />
                  </Form.Item>
                  <Form.Item label={'STIs'} name={['sti']}>
                    <Input.TextArea rows={2} />
                  </Form.Item>
                  <Form.Item label={'Hormonal imbalance symptoms'} name={'hormonal_imbalance_symptoms'}>
                    <Input.TextArea rows={2} />
                  </Form.Item>
                  <Form.Item label={'Last Pap Smear'} name={'last_pap_smear'}>
                    <Input.TextArea rows={2} />
                  </Form.Item>
                  <Form.Item label={'Last Mammogram'} name={'last_mammogram'}>
                    <Input.TextArea rows={2} />
                  </Form.Item>
                  <Form.Item label={'Last Bone Density'} name={'last_bone_density'}>
                    <Input.TextArea rows={2} />
                  </Form.Item>
                  <Form.Item label={'Last Colonoscopy'} name={'last_colonoscopy'}>
                    <Input.TextArea rows={2} />
                  </Form.Item>
                </>
              )}
            </Card>
            <Card title={'Allergies'} type="inner" style={{ marginTop: '8px' }}>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item noStyle name={'overall_allergy'}>
                    <Input.TextArea rows={3} placeholder="Overall Allergy" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card type="inner" style={{ marginTop: '8px' }} title={'Outside Products'}>
              <Row gutter={8}>
                <Col span={12}>
                  <Card title="Outside Medications" bordered={false}>
                    <Form.Item noStyle name={'outside_medications'}>
                      <Input.TextArea rows={3} placeholder="Outside Medications" />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card title="Outside Supplements" bordered={false}>
                    <Form.Item noStyle name={'outside_supplements'}>
                      <Input.TextArea rows={3} placeholder="Outside Supplements" />
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
            </Card>
            <Card type="inner" style={{ marginTop: '8px' }} title={'Medical History'}>
              <Form.Item label={'Family history'} name={'family_history'}>
                <Input.TextArea rows={2} />
              </Form.Item>
              <Form.Item label={'Past Medical History'} name={'past_medical_history'}>
                <Input.TextArea rows={2} />
              </Form.Item>
              <Form.Item label={'Past Surgeries/Hospitalizations'} name={'past_surgeries'}>
                <Input.TextArea rows={2} />
              </Form.Item>
            </Card>
            <Card title={'Social History'} type="inner" style={{ marginTop: '8px' }}>
              <Row gutter={8}>
                <Col span={8}>
                  <Card title="ETOH" bordered={false}>
                    <Form.Item noStyle name={'etoh'}>
                      <Input.TextArea rows={3} placeholder="ETOH" />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="Tobacco" bordered={false}>
                    <Form.Item noStyle name={'tobacco'}>
                      <Input.TextArea rows={3} placeholder="Tobacco" />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="RD" bordered={false}>
                    <Form.Item noStyle name={'rd'}>
                      <Input.TextArea rows={3} placeholder="RD" />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={6}>
                  <Card title="Occupation" bordered={false}>
                    <Form.Item noStyle name={'occupation'}>
                      <Input.TextArea rows={3} placeholder="Occupation" />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={6}>
                  <Card title="Marital Status" bordered={false}>
                    <Form.Item noStyle name={'marital_status'}>
                      <Input.TextArea rows={3} placeholder="Marital Status" />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={6}>
                  <Card title="Children" bordered={false}>
                    <Form.Item noStyle name={'children'}>
                      <Input.TextArea rows={3} placeholder="Children" />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={6}>
                  <Card title="Heritage" bordered={false}>
                    <Form.Item noStyle name={'heritage'}>
                      <Input.TextArea rows={3} placeholder="Heritage" />
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
            </Card>
            <Card title={'ROS'} type="inner" style={{ marginTop: '8px' }}>
              <Row gutter={6}>
                <Col span={8}>
                  <Form.Item label={'Const'} name={'constitution'}>
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'HEENT'} name={'heent'}>
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'CV'} name={'cv'}>
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'Resp'} name={'resp'}>
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'GI'} name={'gi'}>
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'MSK'} name={'msk'}>
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'Neuro'} name={'neuro'}>
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'Derm'} name={'derm'}>
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'Endo'} name={'endo'}>
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'Hemat'} name={'hemat'}>
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'Psych'} name={'psych'}>
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'GU'} name={'gu'}>
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'Lymphatic'} name={'lymph'}>
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'Allergy'} name={'allergy'}>
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={<span style={{ textWrap: 'pretty' }}>Sexual Health</span>} name={'sexual_health'}>
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card title={'Physical'} type="inner" style={{ marginTop: 8 }}>
              <Form.Item noStyle name={'physical'}>
                <Input.TextArea rows={2} />
              </Form.Item>
            </Card>
            <Card title={'AREAS OF HEALTH CONCERN'} type="inner" style={{ marginTop: 8 }}>
              <Form.Item noStyle name={'health_concern'}>
                <Checkbox.Group>
                  <Row>
                    {availableGoals &&
                      availableGoals.map((sag, i) => (
                        <Col key={i} span={8}>
                          <Checkbox value={sag.label} style={{ lineHeight: '32px' }}>
                            {sag.label}
                          </Checkbox>
                        </Col>
                      ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Card>
            <Card title={'LAB REVIEW'} type="inner" style={{ marginTop: 8 }}>
              {!!labResultTableSetting && <DataTable data={labResultTableData} settings={labResultTableSetting} />}
              <Form.Item name={'lab_review'} noStyle>
                <Input type="hidden"></Input>
              </Form.Item>
            </Card>
            <Card title={'RECOMMENDATIONS'} type="inner" style={{ marginTop: 8 }}>
              <Form.Item noStyle name={'recommendations'}>
                <Input.TextArea rows={2} />
              </Form.Item>
            </Card>
            <Card title={'OTHER'} type="inner" style={{ marginTop: 8 }}>
              <Form.Item noStyle name={'other'}>
                <Input.TextArea rows={2} />
              </Form.Item>
            </Card>
            <Card title={'Specialty Testing'} type="inner" style={{ marginTop: 8 }}>
              <Form.Item noStyle name={'specialty_testing'}>
                <Input.TextArea rows={2} />
              </Form.Item>
            </Card>
            <Card title={'Follow up'} type="inner" style={{ marginTop: 8 }}>
              <Form.Item noStyle name={'follow_up'}>
                <Input.TextArea rows={2} />
              </Form.Item>
            </Card>
            <Card title={'Notes'} type="inner" style={{ marginTop: 8 }}>
              <Form.Item noStyle name={'notes'}>
                <Input.TextArea rows={4} />
              </Form.Item>
            </Card>
            <Form.Item wrapperCol={{ offset: 11, span: 16 }} style={{ marginTop: 8 }}>
              <div className='d-flex flex-row gap-2'>
                <Button htmlType="button" onClick={handleDraft}>
                  { medicalNotesItem && medicalNotesItem.status === "draft" ? "Save Draft" : "Draft"}
                </Button>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === '2'} onChange={expandChange('2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography.Title level={5}>Draft Medical Notes {medicalNotesDraft && medicalNotesDraft.length ? <Badge color='green' count={medicalNotesDraft.length ? medicalNotesDraft.length : 0}/> : ""}</Typography.Title>
        </AccordionSummary>
        <AccordionDetails>
          <List
            itemLayout="horizontal"
            loading={medicalNotesLoading}
            dataSource={medicalNotesDraft}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    key="check-note"
                    style={{ margin: '0', paddingInline: 0 }}
                    type="link"
                    icon={<ExportOutlined />}
                    onClick={() => {
                      setCheckingNote(item);
                      setIsModalOpen(true);
                    }}
                  >
                    check
                  </Button>,
                  <Button
                    key="load-into-form"
                    style={{ margin: '0', paddingInline: 0 }}
                    type="link"
                    icon={<ForkOutlined />}
                    onClick={() => {
                      form.setFieldsValue(item.content);
                      setExpanded('1');
                      setSubtitle(`Loaded note created at ${moment(item.creationDate).format('MMM Do, yyyy')}`);
                      setSourceId(item.sourceId);
                      setMedicalNotesItem(item);
                    }}
                  >
                    load this note into form
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar src={item.createdBy?.coverImage || ''} size={48}>
                      {item.source === 'consent' && 'CONSENT'}
                    </Avatar>
                  }
                  title={<span>Note created at {moment(item.creationDate).format('MMM Do, yyyy')}</span>}
                  description={
                    item.source === 'consent' ? (
                      <span>Converted from consent filled by patient</span>
                    ) : (
                      <span>
                        By {item.createdBy?.firstName || ''} {item.createdBy?.lastName || ''}
                        {item.sourceId && ' - completed consent converted note'}
                      </span>
                    )
                  }
                />
              </List.Item>
            )}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === '3'} onChange={expandChange('3')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography.Title level={5}>Previous Medical Notes {medicalNotes && medicalNotes.length ? <Badge color='green' count={medicalNotes.length ? medicalNotes.length : 0}/> : ""}</Typography.Title>
        </AccordionSummary>
        <AccordionDetails>
          <List
            itemLayout="horizontal"
            loading={medicalNotesLoading}
            dataSource={medicalNotes}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    key="check-note"
                    style={{ margin: '0', paddingInline: 0 }}
                    type="link"
                    icon={<ExportOutlined />}
                    onClick={() => {
                      setCheckingNote(item);
                      setIsModalOpen(true);
                    }}
                  >
                    check
                  </Button>,
                  <Button
                    key="load-into-form"
                    style={{ margin: '0', paddingInline: 0 }}
                    type="link"
                    icon={<ForkOutlined />}
                    onClick={() => {
                      form.setFieldsValue(item.content);
                      setExpanded('1');
                      setSubtitle(`Loaded note created at ${moment(item.creationDate).format('MMM Do, yyyy')}`);
                      setSourceId(item.sourceId);
                      setMedicalNotesItem(item);
                    }}
                  >
                    load this note into form
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar src={item.createdBy?.coverImage || ''} size={48}>
                      {item.source === 'consent' && 'CONSENT'}
                    </Avatar>
                  }
                  title={<span>Note created at {moment(item.creationDate).format('MMM Do, yyyy')}</span>}
                  description={
                    item.source === 'consent' ? (
                      <span>Converted from consent filled by patient</span>
                    ) : (
                      <span>
                        By {item.createdBy?.firstName || ''} {item.createdBy?.lastName || ''}
                        {item.sourceId && ' - completed consent converted note'}
                      </span>
                    )
                  }
                />
              </List.Item>
            )}
          />
        </AccordionDetails>
      </Accordion>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={null}
          title={
            <>
              Note created at {moment(checkingNote.creationDate).format('MMM Do, yyyy')}
              <hr />
            </>
          }
          width={'80vw'}
        >
          <MedicalNoteDisplay medicalNote={checkingNote.content} gender={gender} />
        </Modal>
      )}
    </div>
  );
};

export default MedicalNotesPage;
