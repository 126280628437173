import React from 'react';
import { Avatar, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { CiAlarmOn, CiLocationOn, CiMedicalMask, CiStickyNote } from 'react-icons/ci';
import moment from 'moment';
import 'moment-timezone';
import { FiExternalLink } from 'react-icons/fi';
import { VscCallOutgoing, VscRemoteExplorer } from 'react-icons/vsc';
import { FaUserDoctor } from 'react-icons/fa6';

const AppointmentDetail = ({ appointment }) => {
  const subtitle = () => {
    if (appointment.service.type === 'Virtual Consultation') {
      return (
        <div>
          <Typography.Text type={'secondary'}>
            <VscRemoteExplorer style={{ marginRight: 5, top: -1, position: 'relative' }} />
            {appointment.service.type}
          </Typography.Text>
          <Typography.Text underline style={{ marginLeft: 8, fontSize: 'small' }}>
            <a href={'https://thebiostation.doxy.me/sign-in'} target="_blank">
              Virtual Clinic
              <FiExternalLink style={{ marginLeft: 2 }} />
            </a>
          </Typography.Text>
        </div>
      );
    } else if (appointment.service.type === 'Phone Consultation') {
      return (
        <div>
          <Typography.Text type={'secondary'}>
            <VscCallOutgoing style={{ marginRight: 5, top: -1, position: 'relative' }} />
            {appointment.service.type}
          </Typography.Text>
          <Typography.Text underline style={{ marginLeft: 8, fontSize: 'small' }}>
            <a href={`tel:${appointment.patient.phone}`} target="_blank">
              {appointment.patient.phone}
            </a>
          </Typography.Text>
        </div>
      );
    } else {
      return (
        <div>
          <Typography.Text type={'secondary'}>
            <FaUserDoctor style={{ marginRight: 5, top: -1, position: 'relative' }} />
            {appointment.service.type}
          </Typography.Text>
        </div>
      );
    }
  };

  return (
    <div>
      <h5>
        {appointment.service.serviceName}
        {subtitle()}
      </h5>
      <hr />
      <div>
        <div>
          <CiMedicalMask size={20} style={{ marginRight: 5, top: -1, position: 'relative' }} />
          <Typography.Text underline>
            <Link to={`/patient/${appointment.patient.patientId}/appointment`} style={{ color: 'black' }}>
              {`${appointment.patient.firstName} ${appointment.patient.lastName}`}
            </Link>
          </Typography.Text>
        </div>
        <div>
          <CiAlarmOn size={20} style={{ marginRight: 5, top: -1, position: 'relative' }} />
          <Typography.Text type="secondary">
            {moment.tz(appointment.start, appointment.location.timezone).format('MMMM Do, YYYY | hh:mma') +
              ` - ` +
              moment.tz(appointment.end, appointment.location.timezone).format('hh:mma')}
          </Typography.Text>
          <strong>{moment.tz(new Date(), appointment.location.timezone).format(' (z)')}</strong>
        </div>
        <div>
          <CiLocationOn size={20} style={{ marginRight: 5, top: -1, position: 'relative' }} />
          <Typography.Text type="secondary">{appointment.location.name}</Typography.Text>
        </div>
      </div>
      <hr />
      <div className="d-flex align-items-center">
        <Avatar shape={'square'} src={appointment.staff.coverImage} style={{ marginRight: 8 }} />
        <Typography.Text strong underline>
          <Link
            to={`/appointment-management/by-provider?staffId=${appointment.staff.staffId}`}
            style={{ color: 'black' }}
          >
            {`${appointment.staff.firstName} ${appointment.staff.lastName} ${appointment.staff.status !== "active" ? '(inactive)' : ''}`}
          </Link>
        </Typography.Text>
      </div>
      {appointment.note && (
        <>
          <hr />
          <div>
            <CiStickyNote size={20} style={{ marginRight: 5, top: -1, position: 'relative' }} />
            <Typography.Text>{appointment.note}</Typography.Text>
          </div>
        </>
      )}
    </div>
  );
};

export default AppointmentDetail;
