import {
  Avatar,
  Button,
  Col,
  Input,
  message,
  Modal,
  Popconfirm,
  Result,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import { FaHospital, FaHospitalUser, FaNotesMedical, FaUserNurse } from 'react-icons/fa';
import { AiFillCalendar, AiFillClockCircle, AiFillMedicineBox } from 'react-icons/ai';
import React, { useEffect, useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import {
  cancelAppointment,
  completeAppointment,
  generateTitle,
  getStaffSchedules,
  patientNotAppear,
  updateOrRescheduleAppointment,
} from './service';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import DatePickerWithMoment from '../../components/date/DatePickerWithMoment';
import { LiaUserTimesSolid } from 'react-icons/lia';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { setTimezone } from '../../stores/timezone.store';

const ModifyAppointmentModal = ({
  show,
  event,
  locations,
  services,
  providers,
  onFinish,
  eventTitleDifferentiator,
  showCancel,
  showNoShow,
  showComplete,
}) => {
  const pageTimezone = useSelector((state) => state.timezone);
  const originalTimezone = JSON.parse(JSON.stringify(pageTimezone));
  const dispatch = useDispatch();

  const localizer = momentLocalizer(moment);

  const [open, setOpen] = useState(show);
  const [initDone, setInitDone] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment(event.start).startOf('day').toDate());
  const [startEnd, setStartEnd] = useState([moment(event.start).toDate(), moment(event.end).toDate()]);
  const [status, setStatus] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [service, setService] = useState(event.service);
  const [patient] = useState(event.patient);
  const [staff, setStaff] = useState(event.staff);
  const [originalTitle, setOriginalTitle] = useState('');
  const [title, setTitle] = useState('');
  const [location, setLocation] = useState(event.location.stationId);
  const [note, setNote] = useState(event.note);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [showModifySuccess, setShowModifySuccess] = useState(false);
  const [showCancelSuccess, setShowCancelSuccess] = useState(false);
  const [showMarkNoShowSuccess, setMarkNoShowSuccess] = useState(false);
  const [showCompleteSuccess, setMarkCompleteSuccess] = useState(false);
  const [availableServices] = useState(services);
  const [availableProviders] = useState(providers);
  const [events, setEvents] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [result, setResult] = useState(null);
  const [currentDaySchedule, setCurrentDaySchedule] = useState(null);

  const DnDCalendar = withDragAndDrop(Calendar);

  if (!initDone) {
    moment.tz.setDefault(event.location.timezone);
  }

  const calculateStatus = () => {
    const start = startEnd[0];
    const end = startEnd[1];

    if (moment(start).isBefore(moment())) {
      return 'error';
    }

    if (!currentDaySchedule) {
      return 'error';
    }

    const isTimeOverlap = (start1, end1, start2, end2) => {
      return start1.getTime() < end2.getTime() && end1.getTime() > start2.getTime();
    };

    let status = 'success';
    let statusMessage = '';
    if (moment(start).isBefore(currentDaySchedule.start) || moment(end).isAfter(currentDaySchedule.end)) {
      status = 'error';
      statusMessage = 'Selected time slot is not within the schedule';
    } else if (events && events.length) {
      if (!!events.find((e) => isTimeOverlap(e.start, e.end, start, end) && e.type === 'blocked-time')) {
        status = 'error';
        statusMessage = 'Selected time slot is blocked';
      } else if (!!events.find((e) => isTimeOverlap(e.start, e.end, start, end))) {
        const overlappingEvents = events.filter((e) => isTimeOverlap(e.start, e.end, start, end));
        if (!(service.stackable && overlappingEvents.every((e) => e.stackable))) {
          status = 'error';
          statusMessage = 'The selected service cannot overlap with the existing appointment service in this timeslot';
        } else {
          status = 'warning';
          statusMessage =
            'Select timeslot overlaps with existing appointment, but the selected service and existing appointment service are stackable';
        }
      }
    }

    return { status, statusMessage };
  };

  const saveButtonStatus = () => {
    if (status === 'error') {
      return 'disable';
    } else if (
      (service.serviceId === event.service.serviceId &&
        staff.staffId === event.staff.staffId &&
        location === event.location.stationId &&
        note === event.note &&
        moment(selectedDate).isSame(event.start, 'date') &&
        moment(startEnd[0]).isSame(event.start, 'hour') &&
        moment(startEnd[0]).isSame(event.start, 'minute') &&
        moment(startEnd[1]).isSame(event.end, 'hour') &&
        moment(startEnd[1]).isSame(event.end, 'minute')) ||
      !selectedDate ||
      !startEnd[0]
    ) {
      return 'disable';
    } else if (
      !(
        service.serviceId === event.service.serviceId &&
        staff.staffId === event.staff.staffId &&
        location === event.location.stationId &&
        moment(selectedDate).isSame(event.start, 'date') &&
        moment(startEnd[0]).isSame(event.start, 'hour') &&
        moment(startEnd[0]).isSame(event.start, 'minute') &&
        moment(startEnd[1]).isSame(event.end, 'hour') &&
        moment(startEnd[1]).isSame(event.end, 'minute')
      ) ||
      !selectedDate ||
      !startEnd[0]
    ) {
      return 'need-reschedule';
    } else if (note !== event.note && !!note) {
      return 'just-update';
    } else {
      return 'disable';
    }
  };

  const reschedule = async () => {
    const date = moment(selectedDate).startOf('day').format('YYYY-MM-DD');
    try {
      setSubmitting(true);
      let type;
      if (saveButtonStatus() === 'need-reschedule') {
        type = 'reschedule';
      } else {
        type = 'update';
      }
      const rescheduledAppointment = await updateOrRescheduleAppointment(
        event.appointmentId,
        date,
        startEnd[0],
        startEnd[1],
        staff.staffId,
        location,
        service.serviceId,
        note,
        saveButtonStatus()
      );
      setResult({ type, data: rescheduledAppointment.data[0] });
      setShowModifySuccess(true);
    } catch (e) {
      message.error('We ran into problem rescheduling the appointment.');
    } finally {
      setSubmitting(false);
    }
  };

  const moveToPendingTimeslotBlock = (timeout) =>
    setTimeout(() => {
      const timeslot = document.querySelector('.pending-event') || document.querySelector('.error-event');
      if (timeslot) timeslot.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }, timeout);

  const confirmCancel = async () => {
    let cancelStatus;
    const hide = message.loading('Cancelling in progress..', 0, () => {
      if (cancelStatus === 'success') {
        message.success('Successfully Canceled Appointment!');
      } else if (cancelStatus === 'fail') {
        message.error('We ran into problems when canceling the appointment');
      }
    });

    try {
      await cancelAppointment(event.appointmentId);
      cancelStatus = 'success';
      setShowCancelSuccess(true);
      setResult({ type: 'cancel' });
    } catch (e) {
      cancelStatus = 'fail';
    } finally {
      hide();
    }
  };

  const markNoShow = async () => {
    let status;
    const hide = message.loading('Mark absent in progress..', 0, () => {
      if (status === 'success') {
        message.success('Successfully marked the patient absent');
      } else if (status === 'fail') {
        message.error('We ran into problems when marking the patient absent');
      }
    });
    try {
      await patientNotAppear(event.appointmentId);
      setResult({ type: 'noshow' });
      setMarkNoShowSuccess(true);
      status = 'success';
    } catch (e) {
      status = 'fail';
    } finally {
      hide();
    }
  };

  const markComplete = async () => {
    let status;
    const hide = message.loading('Mark complete in progress..', 0, () => {
      if (status === 'success') {
        message.success('Successfully marked the appointment complete');
      } else if (status === 'fail') {
        message.error('We ran into problems when marking the appointment complete');
      }
    });
    try {
      await completeAppointment(event.appointmentId);
      setResult({ type: 'complete' });
      setMarkCompleteSuccess(true);
      status = 'success';
    } catch (e) {
      status = 'fail';
    } finally {
      hide();
    }
  };

  useEffect(() => {
    dispatch(setTimezone(event.location.timezone));
    setInitDone(true);
    return () => {
      dispatch(setTimezone(originalTimezone));
    };
  }, []);

  useEffect(() => setOpen(show), [show]);

  useEffect(() => {
    if (staff && service && location) {
      setTitle(
        generateTitle(
          service,
          patient,
          { staff, location: locations.find((l) => l.value === location) || event.location },
          eventTitleDifferentiator
        )
      );
      if (!originalTitle) {
        setOriginalTitle(
          generateTitle(
            service,
            patient,
            { staff, location: locations.find((l) => l.value === location) || event.location },
            eventTitleDifferentiator
          )
        );
      }

      setLoading(true);
      (async () => {
        try {
          const locationTimezone = locations.find((l) => l.value === location).timezone;

          const schedulesRequest = await getStaffSchedules({
            staff: staff.staffId,
            service: service.serviceId,
            location,
            timezone: locationTimezone,
          });
          const schedules = schedulesRequest.data.data;
          setSchedules(schedules);

          const selectedDateSchedule = schedules.find((s) => moment(s.date, 'YYYY-MM-DD').isSame(selectedDate, 'date'));
          setCurrentDaySchedule(selectedDateSchedule);
        } catch (e) {
          setStatus('error');
          setStatusMessage(`Booking is not possible for the chosen slot. This could be due to a past date time selection,
                      conflict with provider's schedule, overlap with a blocked period, or unavailability of the
                      specific service, provider, location, and date combination.`);
          setEvents([]);
        } finally {
          setLoading(false);
        }
      })();
      moveToPendingTimeslotBlock(500);
    }
  }, [staff, service, location]);

  useEffect(() => {
    if (selectedDate && schedules.length) {
      const selectedDateSchedule = schedules.find((s) => moment(s.date, 'YYYY-MM-DD').isSame(selectedDate, 'date'));
      setCurrentDaySchedule(selectedDateSchedule);
    } else {
      setCurrentDaySchedule(null);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (startEnd[0] && startEnd[1]) {
      const { status, statusMessage } = calculateStatus();
      setStatus(status);
      setStatusMessage(statusMessage);
    } else {
      setStatus('');
      setStatusMessage('');
    }
  }, [events, startEnd]);

  useEffect(() => {
    if (!currentDaySchedule) {
      setStatus('error');
      setStatusMessage('Provider has no schedule for this day. Please select another day.');
      setEvents([]);
    } else {
      const displayingEvents = currentDaySchedule.appointments
        .filter((a) => a.appointmentId !== event.appointmentId && a.status === 'Booked')
        .map((a) => ({
          id: a.appointmentId,
          title: generateTitle(a.service, a.patient, { staff, location: event.location }, eventTitleDifferentiator),
          start: moment(a.start).toDate(),
          end: moment(a.end).toDate(),
          stackable: a.service.stackable,
        }))
        .concat(
          currentDaySchedule.blockedTimes.map((bt) => ({
            end: moment(bt.end).toDate(),
            start: moment(bt.start).toDate(),
            title: (
              <>
                <p>Blocked Time For: {bt.description}</p>
              </>
            ),
            type: 'blocked-time',
            className: 'blocked-event',
          }))
        );
      setEvents(displayingEvents);
    }
  }, [currentDaySchedule]);

  const header = (
    <div className="ms-1 me-4">
      <p className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <div style={{ background: '#5284C2', padding: 4, borderRadius: 4, marginRight: 8 }}>
            <AiFillCalendar style={{ fontSize: 26, color: 'white' }} />
          </div>
          <div className="fw-bold fs-6">
            <div
              style={
                originalTitle !== title
                  ? {
                      textDecoration: 'line-through',
                      color: 'gray',
                      fontStyle: 'italic',
                      fontSize: '12px',
                    }
                  : {}
              }
            >
              {originalTitle}
            </div>
            {originalTitle !== title && <div>{title}</div>}
          </div>
        </div>
        <div style={{ minWidth: 150 }}>
          <Button
            type="primary"
            className="me-1 mb-1"
            disabled={saveButtonStatus() === 'disable'}
            loading={submitting}
            onClick={reschedule}
            style={{ float: 'right' }}
          >
            {saveButtonStatus() === 'need-reschedule' && `Make Reschedule`}
            {saveButtonStatus() === 'just-update' && `Save Changes`}
            {saveButtonStatus() === 'disable' && `Save`}
          </Button>
        </div>
      </p>
      <Space className="d-flex align-items-center">
        <div style={{ marginRight: 16 }}>
          <Typography.Text type={'secondary'}>Current Status: </Typography.Text>
          <Typography.Text>{event.status}</Typography.Text>
          {!!event.changeMadeBy && (
            <Typography.Text type={'secondary'} style={{ fontSize: '10px' }}>
              {' '}
              by {event.changeMadeBy}
            </Typography.Text>
          )}
        </div>
        {showCancel && (
          <div className="d-flex align-items-center">
            <BsTrash style={{ marginRight: 4 }} />
            <Popconfirm
              title="Are you sure to cancel this appointment?"
              onConfirm={confirmCancel}
              okText="Yes"
              cancelText="No"
            >
              <Typography.Link style={{ fontSize: 14 }}>Cancel Appointment</Typography.Link>
            </Popconfirm>
          </div>
        )}
        {showNoShow && (
          <div className="d-flex align-items-center">
            <LiaUserTimesSolid style={{ marginRight: 4 }} />
            <Popconfirm
              title="Are you sure to mark this appointment patient absent?"
              onConfirm={markNoShow}
              okText="Yes"
              cancelText="No"
            >
              <Typography.Link style={{ fontSize: 14 }}>Mark No Show</Typography.Link>
            </Popconfirm>
          </div>
        )}
        {showComplete && (
          <div className="d-flex align-items-center">
            <IoMdCheckmarkCircleOutline style={{ marginRight: 4 }} />
            <Popconfirm
              title="Are you sure to mark this appointment complete?"
              onConfirm={markComplete}
              okText="Yes"
              cancelText="No"
            >
              <Typography.Link style={{ fontSize: 14 }}>Mark Appointment Complete</Typography.Link>
            </Popconfirm>
          </div>
        )}
      </Space>
      <hr />
    </div>
  );

  return (
    <Modal
      className="appointment-window"
      title={
        loading ? (
          <div className="d-flex justify-content-center">
            <Spin tip="Loading" size="large" />
          </div>
        ) : (
          <>{showModifySuccess || showCancelSuccess ? <>Result</> : header}</>
        )
      }
      open={!!open}
      width={900}
      destroyOnClose
      footer={null}
      onCancel={() => onFinish(result)}
    >
      {!loading && (
        <>
          {!(showModifySuccess || showCancelSuccess || showMarkNoShowSuccess || showCompleteSuccess) && (
            <Row>
              <Col span={15}>
                <Row className="d-flex align-items-center mb-4">
                  <FaHospitalUser style={{ fontSize: 20, width: 60 }} />
                  <Typography.Title level={5} style={{ marginBottom: 0 }}>
                    {patient.firstName} {patient.lastName}
                    <Link to={`/patient/${patient.patientId}/`} style={{ marginLeft: 20 }} target="_blank">
                      Patient #{patient.patientId}
                    </Link>
                  </Typography.Title>
                </Row>

                <Row className="d-flex align-items-center mb-4">
                  <AiFillMedicineBox style={{ fontSize: 22, width: 60 }} />
                  <Select
                    showSearch
                    size="large"
                    style={{ minWidth: 360 }}
                    defaultValue={service.serviceId}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={Array.from(
                      availableServices.reduce(
                        (entryMap, e) =>
                          entryMap.set(e.type, [
                            ...(entryMap.get(e.type) || []),
                            {
                              label: e.serviceName,
                              value: e.serviceId,
                              obj: e,
                            },
                          ]),
                        new Map()
                      )
                    ).map(([type, value]) => ({
                      label: type,
                      options: [...value].sort((a, b) => a.label.localeCompare(b.label)),
                    }))}
                    onChange={(value, option) => {
                      setService(option.obj);
                    }}
                  />
                </Row>

                <Row className="d-flex align-items-center mb-4">
                  <FaUserNurse style={{ fontSize: 24, width: 60 }} />
                  <Select
                    size="large"
                    style={{ minWidth: 360 }}
                    showSearch
                    defaultValue={staff.staffId}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    onChange={(value, option) => {
                      setStaff(option.obj);
                      setSelectedDate(null);
                      setStartEnd([null, null]);
                      setEvents([]);
                    }}
                  >
                    {availableProviders.map((p, i) => (
                      <Select.Option key={i} value={p.staffId} label={`${p.firstName} ${p.lastName}`} obj={p}>
                        <div className="d-flex align-items-center">
                          <Avatar size="30" src={p.coverImage} style={{ marginRight: 10 }} />
                          {p.firstName} {p.lastName}
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </Row>

                <Row className="d-flex align-items-center mb-4">
                  <FaHospital style={{ fontSize: 20, width: 60 }} />
                  <Select
                    size="large"
                    value={location}
                    style={{ minWidth: 360 }}
                    onChange={(value) => {
                      const locationTimezone = locations.find((l) => l.value === value).timezone;
                      dispatch(setTimezone(locationTimezone));
                      setLocation(value);
                      setSelectedDate(null);
                      setStartEnd([null, null]);

                      setEvents([]);
                    }}
                    options={locations}
                  />
                </Row>

                <Row className="d-flex align-items-center mb-3">
                  <AiFillClockCircle style={{ fontSize: 20, width: 60 }} />
                  <Space.Compact>
                    <DatePickerWithMoment
                      size={'large'}
                      value={selectedDate ? moment(selectedDate) : ''}
                      format={'MMM DD, YYYY'}
                      style={{ width: 145 }}
                      disabledDate={(date) => !schedules.find((s) => moment(s.date, 'YYYY-MM-DD').isSame(date, 'date'))}
                      onChange={(date) => {
                        const newDate = moment(date).startOf('day').toDate();
                        setSelectedDate(newDate);

                        if (startEnd[0]) {
                          const newStart = new Date(
                            newDate.getFullYear(),
                            newDate.getMonth(),
                            newDate.getDate(),
                            startEnd[0].getHours(),
                            startEnd[0].getMinutes()
                          );
                          const newEnd = new Date(
                            newDate.getFullYear(),
                            newDate.getMonth(),
                            newDate.getDate(),
                            startEnd[1].getHours(),
                            startEnd[1].getMinutes()
                          );
                          setStartEnd([newStart, newEnd]);
                        }
                        const selectedDateSchedule = schedules.find((s) =>
                          moment(s.date, 'YYYY-MM-DD').isSame(newDate, 'date')
                        );
                        const displayingEvents = selectedDateSchedule.appointments
                          .filter((a) => a.appointmentId !== event.appointmentId && a.status === 'Booked')
                          .map((a) => ({
                            id: a.appointmentId,
                            title: generateTitle(a.service, a.patient, staff),
                            start: moment(a.start).toDate(),
                            end: moment(a.end).toDate(),
                          }))
                          .concat(
                            selectedDateSchedule.blockedTimes.map((bt) => ({
                              end: moment(bt.end).toDate(),
                              start: moment(bt.start).toDate(),
                              title: (
                                <>
                                  <p>Blocked Time For: {bt.description}</p>
                                </>
                              ),
                              type: 'blocked-time',
                              className: 'blocked-event',
                            }))
                          );
                        setEvents(displayingEvents);
                      }}
                    />
                    <DatePickerWithMoment.RangePicker
                      picker="time"
                      size={'large'}
                      showSecond={false}
                      hideDisabledOptions={true}
                      minuteStep={5}
                      value={startEnd[0] ? [moment(startEnd[0]), moment(startEnd[1])] : ['', '']}
                      allowEmpty={[false, false]}
                      allowClear={false}
                      order={true}
                      style={{ width: 215 }}
                      format={'hh:mm a'}
                      needConfirm={false}
                      onChange={(time, timeString) => {
                        const newStart = moment(selectedDate)
                          .set('hour', time[0].get('hour'))
                          .set('minute', time[0].get('minute'))
                          .toDate();
                        const newEnd = moment(selectedDate)
                          .set('hour', time[1].get('hour'))
                          .set('minute', time[1].get('minute'))
                          .toDate();
                        setStartEnd([newStart, newEnd]);
                        moveToPendingTimeslotBlock(500);
                      }}
                    />
                  </Space.Compact>
                </Row>
                <div>
                  {status === 'warning' && <Typography.Text type="warning">{statusMessage}</Typography.Text>}
                  {status === 'error' && <Typography.Text type="danger">{statusMessage}</Typography.Text>}
                </div>
                <Row className="d-flex align-items-center">
                  <div>
                    <FaNotesMedical style={{ fontSize: 20, width: 60 }} />
                  </div>
                  <div>
                    <Input.TextArea
                      style={{ width: 360 }}
                      row={2}
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                  </div>
                </Row>
              </Col>
              <Col span={9} style={{ height: 350, overflowY: 'auto' }}>
                {!!currentDaySchedule && (
                  <DnDCalendar
                    defaultDate={selectedDate}
                    localizer={localizer}
                    defaultView={'day'}
                    views={['day']}
                    components={{
                      toolbar: () => <></>,
                    }}
                    slotPropGetter={(date) => {
                      const isDisabled =
                        moment(date).hour() < moment(currentDaySchedule.start).get('hour') ||
                        moment(date).hour() >= moment(currentDaySchedule.end).get('hour');
                      if (isDisabled) {
                        return {
                          className: 'disabled-time',
                        };
                      }
                    }}
                    min={moment(selectedDate).set('hour', 8)}
                    max={moment(selectedDate).set('hour', 22)}
                    timeslots={4}
                    step={15}
                    eventPropGetter={(event, start, end, isSelected) => {
                      let colorClass;
                      if (event.type === 'SELECTED_SLOT') {
                        if (status === 'error') {
                          colorClass = 'error-event draggable-event';
                        } else {
                          colorClass = 'pending-event draggable-event';
                        }
                      } else if (event.type === 'blocked-time') {
                        colorClass = 'blocked-event non-interaction-event';
                      } else {
                        colorClass = 'steelblue-event non-interaction-event';
                      }
                      return {
                        className: `${colorClass}`,
                      };
                    }}
                    events={[
                      ...events,
                      {
                        id: events.length,
                        type: 'SELECTED_SLOT',
                        title: status === 'error' ? 'You can not book this slot' : title,
                        start: startEnd[0],
                        end: startEnd[1],
                        isDraggable: true,
                      },
                    ]}
                    resizable={false}
                    draggableAccessor="isDraggable"
                    onEventDrop={({ event, start, end, allDay }) => {
                      setStartEnd([start, end]);
                    }}
                  />
                )}
              </Col>
            </Row>
          )}
          {showModifySuccess && (
            <Result
              status="success"
              title="Successfully Modified Appointment!"
              extra={[
                <>
                  <h5>Appointment detail after modification:</h5>
                  <p>
                    <strong>Patient: </strong>
                    {patient.firstName} {patient.lastName}
                  </p>
                  <p>
                    <strong>Service: </strong>
                    {service.serviceName}
                  </p>
                  <p>
                    <strong>Provider: </strong>
                    {staff.firstName} {staff.lastName} {staff.status !== "active" ? '(inactive)' : ''} 
                  </p>
                  <p>
                    <strong>Location: </strong>
                    {locations.find((l) => location === l.value).label}
                  </p>
                  <p>
                    <strong>Timezone: </strong>
                    {locations.find((l) => location === l.value).timezone}
                  </p>
                  <p>
                    <strong>Date: </strong>
                    {moment(selectedDate).format('MMMM Do, YYYY')}
                  </p>
                  <p>
                    <strong>Time: </strong>
                    {moment(startEnd[0]).format('hh:mm a')} - {moment(startEnd[1]).format('hh:mm a')}
                  </p>
                  <p>
                    <strong>Note: </strong>
                    {note}
                  </p>
                </>,
              ]}
            />
          )}
          {showCancelSuccess && <Result status="success" title="Successfully Canceled Appointment!" />}
          {showMarkNoShowSuccess && <Result status="success" title="Successfully marked the patient absent" />}
          {showCompleteSuccess && <Result status="success" title="Successfully marked the appointment complete" />}
        </>
      )}
    </Modal>
  );
};
export default ModifyAppointmentModal;
