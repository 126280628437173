import bioApi from '../../api/bioApi';
import {
  APPOINTMENT_AVAILABLE_SERVICES_URL,
  APPOINTMENT_SCHEDULE_URL,
  APPROVE_TREATMENT_PLAN,
  BIOIDS_FILES_URL,
  CONFIRM_TREATMENT_ORDER,
  CONVERT_DRAFT_TO_TREATMENT,
  CREATE_APPOINTMENT,
  CREATE_BLOCKED_TIME,
  DELETE_BIOID,
  DELETE_DRAFT_PLAN,
  DELETE_FILES,
  DELETE_LAB_REPORT,
  FILES_URL,
  GENERATE_BIOID_URL,
  LABREPORTS_FILES_URL,
  NEW_DRAFT_PLAN,
  NEW_TREATMENT_PLAN,
  PATIENT_ADVOCATE_GROUP_URL,
  PATIENT_CHECK_PRESIGN_URL,
  PATIENT_DATA_UPDATE,
  PATIENT_DETAIL_URL,
  PATIENT_MEDICAL_NOTES,
  PLACE_ORDER,
  PROSPECT_PATIENT_DETAIL_URL,
  QUERY_PATIENT_APPOINTMENT,
  QUERY_PATIENT_ORDER,
  REQUEST_TREATMENT_CHANGE,
  SYMPTOMS_AND_GOALS_URL,
  TREATMENT_MEDICATION_CANCELLATION,
  TREATMENT_PROPOSAL_CALIBRATE_URL,
  UPDATE_AND_APPROVE_TREATMENT_PLAN,
  UPDATE_FEE_DISCOUNT,
  UPDATE_TREATMENT_PLAN,
} from '../../api/URLs';
import { searchStaff } from '../staff/staff-search.service';
import { ApplicationRight } from '../../share/RightList';

export const getPatientInfoById = (patientId) => {
  const url = PATIENT_DETAIL_URL.replace(':id', patientId);
  return bioApi.get(url);
};
export const getAppuserInfoById = (appuserId) => {
  const url = PROSPECT_PATIENT_DETAIL_URL.replace(':id', appuserId);
  return bioApi.get(url);
};

export const submitNewTreatmentPlan = async ({
  treatmentPlan,
  patient,
  approved,
  signatureLink,
  reviewers,
  labReport,
}) => {
  return bioApi.post(NEW_TREATMENT_PLAN, {
    treatmentPlan,
    patient,
    signatureLink,
    approved,
    reviewers,
    labReport,
  });
};

export const submitDraftPlan = async ({ treatmentPlan, patient, labReport }) => {
  return bioApi.post(NEW_DRAFT_PLAN, {
    treatmentPlan,
    patient,
    labReport,
  });
};

export const convertDraftToTreatment = async (treatmentId, plan, signatureLink, reviewers, approved) => {
  return bioApi.post(CONVERT_DRAFT_TO_TREATMENT, {
    treatmentId,
    plan,
    signatureLink,
    reviewers,
    approved,
  });
};

export const getTreatmentReviewerList = async () => {
  return searchStaff('right', ApplicationRight.Treatment_Review);
};

export const deleteDraftTreatment = async (draftId) => {
  return bioApi.delete(DELETE_DRAFT_PLAN + draftId);
};

export const populateMedicationInfo = (medications, gender) => {
  return bioApi.post(TREATMENT_PROPOSAL_CALIBRATE_URL, { medications, gender });
};

export const approveTreatmentPlan = (patient, treatmentPlan) => {
  return bioApi.post(APPROVE_TREATMENT_PLAN, {
    treatmentId: treatmentPlan._id,
    patientId: patient._id,
  });
};

export const updateSymptomsAndGoals = (patientId, symptomsAndGoals) => {
  const url = SYMPTOMS_AND_GOALS_URL.replace(':id', patientId);

  return bioApi.post(url, { symptomsAndGoals });
};

export const getSymptomsAndGoals = (patientId) => {
  const url = SYMPTOMS_AND_GOALS_URL.replace(':id', patientId);

  return bioApi.get(url);
};

export const updateMedicineCancellation = (updatedPlan) => {
  return bioApi.post(TREATMENT_MEDICATION_CANCELLATION, { updatedPlan });
};

export const updateTreatmentSelection = (treatmentId, plan, signatureLink) => {
  return bioApi.post(UPDATE_TREATMENT_PLAN, { treatmentId, plan, signatureLink });
};

export const updateAndApproveTreatment = (treatmentId, plan, signatureLink) => {
  return bioApi.post(UPDATE_AND_APPROVE_TREATMENT_PLAN, { treatmentId, plan, signatureLink });
};

export const requestTreatmentChange = (treatmentId, comment) => {
  return bioApi.post(REQUEST_TREATMENT_CHANGE, { treatmentId, comment });
};

export const confirmTreatmentOrderWithPatient = (treatmentObjectId) => {
  return bioApi.post(CONFIRM_TREATMENT_ORDER, { treatmentObjectId });
};

export const placeOrder = (
  patient,
  location,
  medication,
  labResult,
  treatmentId,
  type,
  refillFollowUpDate,
  fee,
  discount,
  totalPrice
) => {
  return bioApi.post(PLACE_ORDER, {
    patient,
    location,
    medication,
    labResult,
    treatmentId,
    type,
    refillFollowUpDate,
    fee,
    discount,
    totalPrice,
  });
};

export const getOrders = (patient) => {
  const url = QUERY_PATIENT_ORDER.replace('{id}', patient.patientId);
  return bioApi.get(url);
};

export const getAppointments = (patient) => {
  const url = QUERY_PATIENT_APPOINTMENT.replace('{id}', patient.patientId);
  return bioApi.get(url);
};
export const getAppointmentAvailableServices = () => {
  return bioApi.get(APPOINTMENT_AVAILABLE_SERVICES_URL);
};

export const getBioIdFiles = (patient) => {
  const url = BIOIDS_FILES_URL.replace(':id', patient.patientId);
  return bioApi.get(url);
};
export const getFiles = (patient) => {
  const url = FILES_URL.replace(':id', patient.patientId);
  return bioApi.get(url);
};
export const getLabreportsFiles = (patient) => {
  const url = LABREPORTS_FILES_URL.replace(':id', patient.patientId);
  return bioApi.get(url);
};
export const getStaffSchedulesByService = (service, timezone) => {
  const url = APPOINTMENT_SCHEDULE_URL + '?service=' + service + '&timezone=' + timezone;
  return bioApi.get(url);
};
export const deleteFiles = (payload) => {
  return bioApi.post(DELETE_FILES, payload);
};
export const scheduleAppointment = (payload) => {
  return bioApi.post(CREATE_APPOINTMENT, payload);
};

export const scheduleBlockedTime = (payload) => {
  return bioApi.post(CREATE_BLOCKED_TIME, payload);
};

export const postPatientData = (patientId, field, value) => {
  return bioApi.post(PATIENT_DATA_UPDATE.replace(':patientId', patientId), {
    field,
    value,
  });
};

export const calculateAge = (birthday) => {
  const birthDate = new Date(birthday);
  const currentDate = new Date();

  let age = currentDate.getFullYear() - birthDate.getFullYear();

  // Check if the birthday hasn't occurred yet this year
  const hasBirthdayPassed =
    currentDate.getMonth() > birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() >= birthDate.getDate());

  // Subtract 1 from age if the birthday hasn't occurred yet
  if (!hasBirthdayPassed) {
    age--;
  }

  return age;
};

export const createMedicalNote = (patientId, data, sourceId, status, note_id) => {
  return bioApi.post(PATIENT_MEDICAL_NOTES.replace(':patientId', patientId), {
    data,
    sourceId,
    status,
    note_id
  });
};

export const getMedicalNotes = (patientId) => {
  return bioApi.get(PATIENT_MEDICAL_NOTES.replace(':patientId', patientId));
};

export const checkPresignUrl = (type, patientId, fileName, orderId) => {
  return bioApi.get(
    PATIENT_CHECK_PRESIGN_URL.replace(':type', type)
      .replace(':id', patientId)
      .replace(':name', fileName || 'exclude')
      .replace(':orderId', orderId || 'exclude')
  );
};

export const createBioId = (patientData, yourResult, monitor1, monitor2) => {
  /*
   yourResult/ monitor1/ monitor2:
   {
      data: [{biomarker, labData, isAbnormal}],
      collectionDate
   }
   */
  return bioApi.post(GENERATE_BIOID_URL, {
    patient: {
      firstName: patientData.profile.firstName,
      lastName: patientData.profile.lastName,
      patientId: patientData.patientId,
      currentLabReportId: patientData.currentLabReportId,
      dob: patientData.profile.dob,
      gender: patientData.profile.gender,
      _id: patientData._id,
    },
    yourResult,
    monitor1,
    monitor2,
  });
};

export const getPatientAdvocateGroup = (patientId) => {
  return bioApi.get(PATIENT_ADVOCATE_GROUP_URL.replace(':id', patientId));
};

export const changePatientAdvocateGroup = (patientId, newPAs) => {
  return bioApi.post(PATIENT_ADVOCATE_GROUP_URL.replace(':id', patientId), { newPAs });
};

export const deleteLabReport = (labReportObjectId) => {
  return bioApi.delete(DELETE_LAB_REPORT + '/' + labReportObjectId);
};

export const deleteBioId = (bioIdObjectId) => {
  return bioApi.delete(DELETE_BIOID + '/' + bioIdObjectId);
};

export const updateTreatmentPlanFeeAndDiscount = (treatmentPlanId, amount, type) => {
  return bioApi.post(UPDATE_FEE_DISCOUNT, { treatmentPlanId, amount, type });
};
