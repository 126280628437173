import React from 'react';
import { FormatDateToString } from '../../../share/StringHelper';
import bioApi from '../../../api/bioApi';
import Pager from '../../../components/Pager/PagerControl';
import { WithBio } from '../../../components/mycomponent/WithBio';
import { Table, Alert } from 'react-bootstrap';
import { Modal } from 'antd';
import VisibilityIcon from '@mui/icons-material/Visibility';

const AnnouncementList = ({staffId}) => {

  const handlePageIndexChange = (i) => {
    setPagerSetting(ps => ({...ps, pageIndex: i}));
    fetchNotifications(i+1);
  };

  const [notifications, setNotifications] = React.useState(null);
  const [pagerSetting, setPagerSetting] = React.useState({
    totalCount: 0,
    pageSize: 10,
    pageIndex: 0,
    firstText: 'First',
    prevText: 'Prev',
    nextText: 'Next',
    lastText: 'Last',
    recordTextFormat: "{0}/{1}",
    goWidth: 50,
    showLinkNum: 5,
    callBack: handlePageIndexChange
  });
  const [loading, setLoading] = React.useState(false);
  const [al, setAl] = React.useState(null);
  const [showDetails, setShowDetails] = React.useState(null);

  React.useEffect(() => {
    fetchNotifications();
  },[]);

  const fetchNotifications = async (overwritepage) => {
    setLoading(true);
    try{
      const results = await bioApi.post('/notification/communication_list', {
        "staffId": staffId, "limit": 10, page: overwritepage || pagerSetting.pageIndex + 1
      }).then(res => res.data);
      if(results.status === 200){
        setPagerSetting(ps => ({...ps, totalCount: results.totalCount}))
        setNotifications(results.data);
      } else {
        setAl({text: results.message || `Cannot fetch at this time`, variant: "danger"});
      }
    }catch(err){
      setAl({text: err.message, variant: "danger"});
    };
    setLoading(false);
  };

  return (
    <div className="box">
      {
        al ? <Alert variant={al.variant} onClose={() => setAl(null)} dismissible>
          {al.text}
        </Alert> : null
      }
      {
        loading && <Alert>
          Loading ... 
        </Alert>
      }
      {
        notifications && notifications.length && <Table bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Send Date</th>
            <th>Notification ID</th>
            <th>Title</th>
            <th>CreatedBy</th>
            <th>Publish To</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            notifications.map((not, noti) => (
              <tr key={not.announcementID}>
                <td>{noti+1}</td>
                <td>{FormatDateToString(not.createDate)}</td>
                <td>{not._id.substr(not._id.length - 8, 8)}</td>
                <td>{not.title}</td>
                <td>{not.creatorName}</td>
                <td>
                    {(() => {
                      switch (not.type) {
                        case 'communication': 
                          return (
                            not.locations.length > 0 ? 'Selected Locations' : 'Selected Patients'
                          )
                        case 'communication_all': return ('All Users')
                        default:
                          return null
                      }
                    })()}
                </td>
                <td><div onClick={() => setShowDetails(not)}>
                  <VisibilityIcon/>
                </div></td>
              </tr>
            ))
          }
        </tbody>
      </Table>
      }
      {!loading && notifications && notifications.length && <div style={{ display: "flex" }}>
        <div style={{ flex: "1" }}></div>
        <div className="panel-body" style={{ padding: "10px 15px" }}>
          <Pager {...pagerSetting} />
        </div>
      </div>}

      {showDetails && <Modal onOk={() => setShowDetails(null)} onCancel={() => setShowDetails(null)} onCl title={`Notification Details ${showDetails._id}`} open={true} cancelButtonProps={{ disabled: true, hidden: true }}>
        <div style={{fontSize: 20, fontWeight: 600}}>{showDetails.title}</div>
        <div style={{fontSize: 16}}>{showDetails.message}</div>

        {
          showDetails.patientIds && showDetails.patientIds.length ? 
          <div>
            {
              showDetails.patientIds
            }
          </div>:
          <div>**Sent to all patients / prospect-patients**</div>
        }

        {
          showDetails.locations.length > 0 && (
            <div style={{ maxHeight: 130, width: "100%", overflowY: "scroll" }}>
              <Table>
                  <thead>
                      <tr>
                          <th>No.</th>
                          <th>Location Name</th>
                      </tr>
                  </thead>
                  <tbody>
                      {
                          showDetails.locations.map((item, index) => {
                              return(
                                  <tr>
                                      <td>{index + 1}</td>
                                      <td>{item}</td>
                                  </tr>
                              )
                          })
                      }
                  </tbody>
              </Table>
          </div>
          )
        }
      </Modal>}

    </div>
  );
};

export default WithBio(AnnouncementList);

// import React from 'react';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import moment from 'moment';
// import { connect } from 'react-redux';
// import { BsSearch } from 'react-icons/bs'
// import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';

// import { FormatDateToString, GetDefaultFromDate, GetDefaultToDate, ConvertToUTCDate, DATE_FORMAT } from '../../../share/StringHelper';

// import reviewIcon from '../../../assets/images/review.png';

// import NotificationModal from './NotificationModal'

// import DateInput from '../../../components/date/DateInput';
// import bioApi from '../../../api/bioApi';

// import BoxLoader from '../../../components/loading/BoxLoader';
// import { WithBio } from '../../../components/mycomponent/WithBio';
// import Pager from '../../../components/Pager/PagerControl';
// import { LOCATION_SHORT_LIST } from '../../../api/URLs';

// function getCenterName(centerArray, centerid) {
//     for (var i = 0; i < centerArray.length; i++) {
//         var item = centerArray[i];
//         if (item.stationId === centerid) {
//             return item.name;
//         }
//     }
//     return '';
// }

// class NotificationList extends React.Component {

//     constructor(props) {
//         super(props)

//         this.mapItem = this.mapItem.bind(this);
//         this.requestGetData = this.requestGetData.bind(this);
//         this.getPagerSetting = this.getPagerSetting.bind(this);
//         this.handlePageIndexChange = this.handlePageIndexChange.bind(this);
//         this.getStations = this.getStations.bind(this);

//         this.state = {
//             ...this.getInitData(),
//             promotionData: [],
//             pagerSetting: this.getPagerSetting(),
//             notificationData: this.getInitNotificationData()
//         };

//         this.getStations();
//         this.requestGetData();
//     }

//     getInitNotificationData() {
//         return {
//             showReviewModal: false,
//             sendType: 'all',
//             title: '',
//             message: '',
//             selectRegion: {
//                 selectAll: false,
//                 ids: []
//             },
//             formData: {
//                 donors: [],
//             }
//         }
//     }

//     getStations() {
//         this.setState({
//             loading: true
//         })
//         var pathstring = LOCATION_SHORT_LIST;
//         bioApi.post(pathstring).then(response => {
//             if (response.data.status !== 200) {
//                 this.setState({
//                     loading: false
//                 })
//                 alert(response.data.message);
//             } else {
//                 this.setState({
//                     plasmaData: response.data.data,
//                     loading: false,
//                 });
//             }
//         }).catch((err) => {
//             this.setState({ loading: false })
//             alert('Network error');
//         })
//     }

//     getInitData() {
//         return {
//             loading: false,
//             searchType: "title",
//             fromDate: GetDefaultFromDate(),
//             toDate: GetDefaultToDate(),
//             searchForm: {}
//         }
//     }

//     getPlasmaIdsData(plasmaIds) {
//         return [
//             {
//                 headQuarterData: {
//                     plasmaData: {
//                         hasAccess: true,
//                         selectAll: false,
//                         selectedIds: plasmaIds
//                     }
//                 }
//             }
//         ]
//     }

//     getPagerSetting() {
//         return {
//             totalCount: 0,
//             pageSize: 10,
//             pageIndex: 0,
//             firstText: 'First',
//             prevText: 'Prev',
//             nextText: 'Next',
//             lastText: 'Last',
//             recordTextFormat: "{0}/{1}",
//             goWidth: 50,
//             showLinkNum: 5,
//             callBack: this.handlePageIndexChange
//         };
//     }

//     requestGetData() {
//         setTimeout(() => {
//             this.setState({
//                 loading: true
//             });
//             var pathstring = '/notification/communication_list';
//             var formData = {
//                 ...this.state.searchForm,
//                 "limit": 10,
//                 "page": this.state.pagerSetting.pageIndex + 1
//             };
//             if (this.state.fromDate !== '') {
//                 formData['fromDate'] = ConvertToUTCDate(this.state.fromDate + 'T00:00:00.000Z');
//             }
//             if (this.state.toDate !== '') {
//                 formData['toDate'] = ConvertToUTCDate(this.state.toDate + 'T23:59:59.999Z');
//             }
//             try {
//                 bioApi.post(pathstring, formData).then(response => {
//                     if (response.data.status === 200) {
//                         this.setState({
//                             loading: false,
//                             promotionData: response.data.data,
//                             pagerSetting: {
//                                 ...this.state.pagerSetting,
//                                 totalCount: response.data.totalCount
//                             }
//                         })
//                     } else {
//                         this.setState({ loading: false })
//                         alert(response.data.message);
//                     }
//                 }).catch((err) => {
//                     this.setState({ loading: false })
//                     alert('Network error');
//                 })
//             } catch (err) {
//                 this.setState({ loading: false })
//                 alert('Network error');
//             }
//         }, 0);
//     }

//     handlePageIndexChange(index) {
//         this.setState({
//             pagerSetting: {
//                 ...this.state.pagerSetting,
//                 pageIndex: index,
//             }
//         })
//         this.requestGetData();
//     }

//     requestFindDonorById(donorIDs) {
//     }

//     mapItem(item, index) {
//         var controlArray = [];
//         controlArray.push(<td key={"notilist " + index}>
//             <div onClick={() => {
//                 var notificationData = this.state.notificationData;
//                 notificationData.title = item.title;
//                 notificationData.message = item.message;
//                 if (item.type === 'donor_message') {
//                     notificationData.sendType = 'donor_message';
//                     this.setState({
//                         loading: true
//                     })
//                     var donorIDs = item.donorIDGroup;
//                     bioApi.post('/donors/check-exist-donors', {
//                         donorIDs
//                     }).then(res => {
//                         let { foundIDs } = res.data.data
//                         var notificationData = this.state.notificationData;
//                         if (foundIDs && foundIDs.length > 0) {
//                             notificationData.formData.donors = foundIDs;
//                         }
//                         notificationData.showReviewModal = true;
//                         this.setState({
//                             loading: false,
//                             notificationData: notificationData
//                         })
//                     }).catch(err => {
//                         this.props.showAlert('Patient not found')
//                         this.setState({
//                             loading: false,
//                         })
//                     })
//                 }
//                 else if (item.type === 'plasma_message') {
//                     notificationData.sendType = 'plasma_message';
//                     notificationData.showReviewModal = true;
//                     notificationData.selectRegion.ids = item.plasmaIdGroup;
//                     notificationData.selectRegion.selectAll = false;
//                     this.setState({
//                         notificationData: notificationData
//                     })
//                 }
//                 else if (item.type === "all_message") {
//                     notificationData.sendType = 'all';
//                     notificationData.showReviewModal = true;
//                     this.setState({
//                         notificationData: notificationData
//                     })
//                 } else if (item.type === 'news' || item.type === 'campaign' || item.type === 'survey') {
//                     var promotionId = item.redirectObjectID;
//                     this.props.history.push({ pathname: '/promotion/review', state: { id: promotionId, type: item.type, notification: true, status: 'approved' } });
//                 }
//             }}>
//                 <img src={reviewIcon} alt="edit" className="icon-edit" />
//             </div>
//         </td>)

//         var publishto = 'All app users';
//         if (item.type === 'donor_message') {
//             publishto = item.donorIDGroup.join(',');
//         }
//         else if (item.type === 'plasma_message') {
//             publishto = '';
//             for (var i = 0; i < item.plasmaIdGroup.length; i++) {
//                 var plasmaid = item.plasmaIdGroup[i];
//                 if (i > 0) {
//                     publishto += ',';
//                 }
//                 publishto += getCenterName(this.state.plasmaData, plasmaid);
//             }
//         }

//         return <tr key={'itemyour' + item._id}>
//             <td>{FormatDateToString(item.creationTS)}</td>
//             <td>{item._id.substr(item._id.length - 8, 8)}</td>
//             <td style={{ width: "30%" }}>{item.title}</td>
//             <td>{item.creatorName}</td>
//             <td style={{ maxWidth: 120, whiteSpace: "nowrap", textOverflow: "ellipsis", wordBreak: "break-all", overflow: "hidden" }}>{publishto}</td>
//             {controlArray}
//         </tr>
//     }

//     render() {
//         return (
//             <div>
//                 <div className="box">
//                     <BoxLoader show={this.state.loading} />
//                     <div className="box">
//                         <div className="box-marketing-bd box-body table-responsive" style={{ padding: "0 40px 0 40px" }}>
//                             <div style={{ display: 'flex' }}>
//                                 <div style={{ display: 'flex' }}>
//                                     <Form.Label style={{ margin: 'auto', width: 80 }}>Search:</Form.Label>
//                                 </div>
//                                 <select className="form-control input-sm" style={{ width: "auto", padding: '3px 6px' }} onChange={(event) => {
//                                     this.setState({
//                                         searchType: event.target.value
//                                     })
//                                 }}>
//                                     <option value="title" checked>Title</option>
//                                     <option value="creator">Creator</option>
//                                 </select>
//                                 <span style={{ fontSize: 20, color: '#ddd', fontFamily: 'Source Sans Pro', margin: 'auto 5px' }}>&#8212;</span>
//                                 <InputGroup style={{ marginLeft: 5, marginTop: 'auto', marginBottom: 'auto', flex: 1 }}>
//                                     <FormControl
//                                         size="sm"
//                                         placeholder={
//                                             this.state.searchType === "title" ? "Please input title" : "Please input creator"
//                                         }
//                                         value={this.state.searchValue}
//                                         onChange={(event) => {
//                                             this.setState({
//                                                 searchValue: event.target.value
//                                             })
//                                         }}
//                                     />
//                                     <Button size="sm" variant="outline-secondary" id="button-addon2" onClick={() => {
//                                         var searchForm = {};
//                                         if (this.state.searchValue.trim().length > 0) {
//                                             searchForm['searchParam'] = this.state.searchValue.trim();
//                                             searchForm['searchType'] = this.state.searchType;
//                                         }

//                                         this.setState({
//                                             pagerSetting: this.getPagerSetting(),
//                                             searchForm: searchForm
//                                         })
//                                         this.requestGetData();
//                                     }}>
//                                         <BsSearch />
//                                     </Button>
//                                 </InputGroup>
//                                 <div style={{ flex: 1 }}></div>
//                                 <div style={{ display: 'flex' }}>
//                                     <Form.Label style={{ margin: 'auto', width: 80 }}>Date:</Form.Label>
//                                 </div>
//                                 <div>
//                                     <DatePicker
//                                         selected={this.state.fromDate !== '' ? moment(this.state.fromDate).toDate() : null}
//                                         onChange={val => {
//                                             var fromDate = moment(val).format(DATE_FORMAT)
//                                             this.setState({
//                                                 pagerSetting: this.getPagerSetting(),
//                                                 fromDate: fromDate
//                                             })
//                                             this.requestGetData();
//                                         }}
//                                         dateFormat="yyyy-MM-dd"
//                                         customInput={<DateInput width={150} />}
//                                     />
//                                 </div>
//                                 <span style={{ fontSize: 20, color: '#ddd', fontFamily: 'Source Sans Pro', margin: 'auto 5px' }}>&#8212;</span>
//                                 <div>
//                                     <DatePicker
//                                         selected={this.state.toDate !== '' ? moment(this.state.toDate).toDate() : null}
//                                         onChange={val => {
//                                             var toDate = moment(val).format(DATE_FORMAT)
//                                             this.setState({
//                                                 pagerSetting: this.getPagerSetting(),
//                                                 toDate: toDate
//                                             })
//                                             this.requestGetData();
//                                         }}
//                                         dateFormat="yyyy-MM-dd"
//                                         customInput={<DateInput width={150} />}
//                                     />
//                                 </div>
//                             </div>
//                             <hr className="hr" style={{ marginTop: 5, marginBottom: 5 }} />
//                             <table className="table table-marketing" style={{ fontSize: 14 }}>
//                                 <tbody>
//                                     <tr>
//                                         <th>Send Date(EST)</th>
//                                         <th>Notification ID</th>
//                                         <th>Title</th>
//                                         <th>Creator</th>
//                                         <th>Publish To</th>
//                                         <th>Action</th>
//                                     </tr>
//                                     {
//                                         this.state.promotionData.map(this.mapItem)
//                                     }
//                                 </tbody>
//                             </table>
//                         </div>
//                         <div style={{ display: "flex" }}>
//                             <div style={{ flex: "1" }}></div>
//                             <div className="panel-body" style={{ padding: "10px 15px" }}>
//                                 <Pager {...this.state.pagerSetting} />
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <NotificationModal
//                     disableClose={true}
//                     sendType={this.state.notificationData.sendType}
//                     title={this.state.notificationData.title}
//                     message={this.state.notificationData.message}
//                     selectRegion={this.state.notificationData.selectRegion}
//                     centers={this.state.plasmaData}
//                     formData={this.state.notificationData.formData}
//                     showReviewModal={this.state.notificationData.showReviewModal}
//                     handleReviewModalClose={() => {
//                         this.setState({
//                             notificationData: this.getInitNotificationData()
//                         })
//                     }}
//                     handleReviewModalSubmit={() => {

//                     }}
//                 />
//             </div>
//         )
//     }
// };

// export default WithBio(NotificationList);
