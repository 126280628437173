import React from 'react'
import { connect } from 'react-redux';
import { AiOutlineRightCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs'

import NotificationModal from './NotificationModal'

import bioApi from '../../../api/bioApi'
import RegionLevelModal from '../../../components/modal/RegionLevelModal'
import BoxLoader from '../../../components/loading/BoxLoader';
import { WithBio } from '../../../components/mycomponent/WithBio';
import { NOTIFICATION_SEND_ALL_URL, NOTIFICATION_SEND_LOCATION_NAME, NOTIFICATION_SEND_PATIENT_URL, PATIENTS_CHECK_EXIST_URL } from '../../../api/URLs';

import '../../../styles/station.css';
import { listAllLocations } from '../../patient/patient-search.service';
import { Select } from 'antd';

function getCenterName(centerArray, centerid) {
    for (var i = 0; i < centerArray.length; i++) {
        var item = centerArray[i];
        if (item.locationID === centerid) {
            return item.name;
        }
    }
    return '';
}

function getReviewStatus(title, message, sendType, donors, centers, selectall, selectedLocations) {
    if (!title || title === '') {
        return true;
    }
    if (!message || message === '') {
        return true;
    }
    if (sendType === 'all') {
        return false;
    } else if (sendType === 'plasma_message') {
        if (centers.length > 0 || selectall === true) {
            return false;
        }
    } else if (sendType === 'locations') {
        if (selectedLocations.length > 0) {
            return false;
        }
    } else {
        if (donors.length > 0) {
            return false;
        }
    }
    return true;
}

class NewNotification extends React.Component {
    constructor(props) {
        super(props);

        this.handleRegionLevelModalConfirm = this.handleRegionLevelModalConfirm.bind(this);
        this.handleReviewModalSubmit = this.handleReviewModalSubmit.bind(this);

        this.state = {
            ...this.initData(),
            allLocations: []
        }

        this.handleCenterChange = this.handleCenterChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    initData() {
        return {
            textareaValue: '',
            textareaHeight: 28,
            sendType: "all",
            checked: { "0": true },
            title: '',
            message: '',
            loading: false,
            showModal: false,
            showReviewModal: false,
            showRegionLevelModal: false,
            plasmaData: [],
            selectRegion: {
                            selectAll: false,
                            ids: []
                        },
            list: [],
            formData: this.emptyFormData(),
            isSubmitted: false,
            selectedLocations: []
        }
    }

    componentDidMount(){
        this.fetchAllLocations();
    }

    fetchAllLocations() {
        listAllLocations().then((response) => {
            if (response.data.status !== 200) {
                alert(response.data.message);
            } else {
                this.setState({
                    allLocations: response && response.data && response.data.data ? response.data.data.map(it => ({...it, label: it.name, value: it.sfName ? it.sfName : it.name})) : []
                })
            }
        });
    }

    emptyFormData() {
        return {
            _id: null,
            name: "",
            donors: [],
        }
    }

    handleTitleChange = (event) => {
        this.setState({
            title: event.target.value
        })
    }

    handleChange = (event) => {
        this.setState({
            message: event.target.value
        })
    }

    handleCenterChange(index) {
        var checked = this.state.checked;

        if (checked[index + '']) {
            delete checked[index + '']
        } else {
            checked[index + ''] = true;
        }

        if (index === 0) {
            checked = { "0": true };
        } else {
            if (Object.keys(checked).length === 0 || this.state.plasmaData.length === Object.keys(checked).length) {
                checked = { "0": true };
            } else {
                delete checked['0'];
            }
        }

        this.setState({
            checked: checked
        });
    }

    requestFindDonorById() {
        const q = this.state.textareaValue.trim()
        if (q) {
            let donorIDs = q.split('\n');
            var myDonorIDs = [];
            var foundedIDs = [];
            for (var i = 0; i < donorIDs.length; i++) {
                var mydonorid = donorIDs[i];
                if (this.state.formData.donors.find(s => mydonorid === s.donorID)) {
                    if (!foundedIDs.find(s => mydonorid === s)) {
                        foundedIDs.push(mydonorid);
                    }
                } else {
                    if (!myDonorIDs.find(s => mydonorid === s)) {
                        myDonorIDs.push(mydonorid);
                    }
                }
            }
            if (myDonorIDs.length === 0) {
                this.setState({
                    textareaValue: foundedIDs.join('\n')
                })
                alert('IDs is already added: ' + foundedIDs.join(', '))
                return
            }
            if (foundedIDs.length > 0) {
                this.setState({
                    textareaValue: foundedIDs.join('\n')
                })
            } else {
                this.setState({
                    textareaValue: ''
                })
            }
            donorIDs = myDonorIDs;
            this.setState({
                loading: true
            })
            bioApi.post(PATIENTS_CHECK_EXIST_URL, {
                patientIds: donorIDs,
            }).then(res => {
                let { found, notFoundMessage } = res.data.data
                const { formData } = this.state;
                if (found && found.length > 0) {
                    formData.donors = formData.donors.concat(found);
                }
                if (notFoundMessage) {
                    alert(notFoundMessage);
                }
                this.setState({
                    textareaHeight: 28,
                    loading: false,
                    formData
                })
            }).catch(err => {
                this.props.showAlert('Patient not found')
                this.setState({
                    loading: false,
                    textareaValue: '',
                    textareaHeight: 28
                })
            })
        }
    }

    handleRegionLevelModalConfirm(selectRegion, plasmaData) {
        this.setState({
            showRegionLevelModal: false,
            selectRegion: selectRegion,
            plasmaData: plasmaData
        })
    }

    handleReviewModalSubmit() {
        var result = window.confirm('Are you sure you want to push notification.');
        if (result) {
            this.setState({
                showReviewModal: false,
                loading: true
            })
            try {
                if (this.state.title === undefined || this.state.title === '') {
                    alert('Please input title.');
                    return;
                }

                if (this.state.message === undefined || this.state.message === '') {
                    alert('Please input message.');
                    return;
                }

                var formData = null;
                var pathstring = NOTIFICATION_SEND_PATIENT_URL;
                if (this.state.sendType === 'all') {
                    pathstring = NOTIFICATION_SEND_ALL_URL;
                    formData = {
                        title: this.state.title,
                        message: this.state.message,
                    };
                } else if (this.state.sendType === 'locations') {
                    pathstring = NOTIFICATION_SEND_LOCATION_NAME;
                    formData = {
                        title: this.state.title,
                        message: this.state.message,
                        locations: this.state.selectedLocations
                    };
                }
                // else if (this.state.sendType === 'plasma_message') {
                //     if (this.state.selectRegion[0].headQuarterData.plasmaData.selectAll === false) {
                //         formData = {
                //             sendType: this.state.sendType,
                //             staffId: this.props.loggedInUser._id,
                //             title: this.state.title,
                //             message: this.state.message,
                //             plasmaIds: this.state.selectRegion[0].headQuarterData.plasmaData.selectedIds,
                //         };
                //     } else {
                //         pathstring = '/notification/push/all';
                //         formData = {
                //             staffId: this.props.loggedInUser._id,
                //             title: this.state.title,
                //             message: this.state.message,
                //         };
                //     }
                // } 
                else {
                    var donors = [];
                    for (var i = 0; i < this.state.formData.donors.length; i++) {
                        var item = this.state.formData.donors[i];
                        donors.push(item.appuserId);
                    }
                    if (donors.length === 0) {
                        alert('Please select patient(s)');
                        return;
                    }
                    formData = {
                        title: this.state.title,
                        message: this.state.message,
                        patientIds: donors
                    };
                }

                bioApi.post(pathstring, formData).then(response => {
                    this.setState({
                        loading: false
                    })
                    if (response.data.status === 200) {
                        this.setState({
                            ...this.initData()
                        })
                        alert('Successful!');
                    } else {
                        alert(response.data.message);
                    }
                }).catch((err) => {
                    this.setState({ loading: false })
                    alert('Network error');
                })
            } catch (err) {
                this.setState({
                    loading: false
                })
                alert('Network error');
            }
        }
    }

    render() {
        return (
            <div>
                <div className="box" style={{ width: "100%", padding: 20, paddingLeft: 40, paddingRight: 40, display: "flex", backgroundColor: "white" }}>
                    <BoxLoader show={this.state.loading} />
                    <div className={"col-md-12 container-form"}>
                        <div className={"box-form"}>
                            <div className={"title-qrcode"}>
                                <h3 className={'qrcode-h3'}>Bio Station</h3>
                                <span className={"text-des"}>Push notification</span>
                            </div>
                            <div className={"form-input"}>
                                <div style={{ display: "flex", marginTop: "10px" }}>
                                    <span style={{ width: "100px", marginTop: "auto", marginBottom: "auto" }}><span style={{ color: "red" }}>*</span>Title: </span>
                                    <input type="text" className={"form-control input-sm"} value={this.state.title} placeholder="Notification title" onChange={this.handleTitleChange} />
                                </div>
                                <div style={{ display: "flex", marginTop: "10px" }}>
                                    <span style={{ width: "100px", marginBottom: "auto" }}><span style={{ color: "red" }}>*</span>Message: </span>
                                    <textarea rows="5" style={{ resize: "none" }} className={"form-control input-sm"} value={this.state.message} placeholder="Notification message" onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className={"box-form mt-2"} style={{ display: "flex" }}>
                            <Form.Check
                                type={'radio'}
                                checked={this.state.sendType === 'all'}
                                label={'Send to all users'}
                                onChange={() => {}}
                                onClick={() => {
                                    this.setState({
                                        sendType: "all"
                                    });
                                }}
                            />
                        </div>
                        <div className={"box-form mt-2"}>
                            <Form.Check
                                type={'radio'}
                                checked={this.state.sendType === "donor_message"}
                                label={
                                    'Select Patient(s) to send'
                                }
                                onChange={() => {}}
                                onClick={() => {
                                    this.setState({
                                        sendType: "donor_message"
                                    });
                                }}
                            />
                            {this.state.sendType === "donor_message" && (this.state.formData.donors && this.state.formData.donors.length > 0 ? null : (
                                <div className='mt-2'>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                        <div style={{ flex: 1 }}>
                                            <div className="input-group input-group-sm" style={{ display: "flex" }}>
                                                <InputGroup>
                                                    <FormControl
                                                        size="sm"
                                                        as="textarea"
                                                        placeholder="Patient ID | App user ID"
                                                        rows={1}
                                                        value={this.state.textareaValue}
                                                        onChange={(event) => {
                                                            var valueString = event.target.value;
                                                            var lines = valueString.split("\n").length;
                                                            var textareaHeight = 100;
                                                            if (lines < 6) {
                                                                textareaHeight = (lines * 28 - (lines - 1) * 10)
                                                            }
                                                            this.setState({
                                                                textareaValue: valueString,
                                                                textareaHeight: textareaHeight
                                                            })
                                                        }}
                                                    />
                                                    <Button size="sm" variant="outline-secondary" id="button-addon2" onClick={() => {
                                                        this.requestFindDonorById();
                                                    }}>
                                                        <BsSearch />
                                                    </Button>
                                                </InputGroup>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ color: "#adadad", marginTop: 3, fontSize: 12 }}><b sytle={{ color: "#000" }}>Note&#8758; &nbsp;</b>To add multiple patients, please insert the Patient ID | App user ID in each row</div>
                                </div>
                            ))}
                        </div>
                        {this.state.sendType === "donor_message" && (this.state.formData.donors && this.state.formData.donors.length > 0 ? (
                            <div className={"box-form"} style={{ display: "flex", flex: 1 }}>
                                <div style={{ flex: 1 }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                        {
                                            this.state.formData.donors && this.state.formData.donors.length > 0 ? (
                                                <div style={{ flex: 1 }}>
                                                    <div style={{ display: "flex" }}>
                                                        <textarea
                                                            disabled={this.state.sendType !== "donor_message"}
                                                            className="form-control"
                                                            value={this.state.textareaValue}
                                                            style={{ height: 200 }}
                                                            placeholder="Patient ID | App user ID"
                                                            onChange={(event) => {
                                                                var valueString = event.target.value;
                                                                var lines = valueString.split("\n").length;
                                                                var textareaHeight = 100;
                                                                if (lines < 6) {
                                                                    textareaHeight = (lines * 28 - (lines - 1) * 10)
                                                                }
                                                                this.setState({
                                                                    textareaValue: valueString,
                                                                    textareaHeight: textareaHeight
                                                                })
                                                            }} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{ flex: 1 }}>
                                                    <div className="input-group input-group-sm" style={{ display: "flex" }}>
                                                        <InputGroup style={{ marginLeft: 5, marginTop: 'auto', marginBottom: 'auto', flex: 1 }}>
                                                            <FormControl
                                                                size="sm"
                                                                placeholder={
                                                                    this.state.searchType === "title" ? "Please input title" : "Please input creator"
                                                                }
                                                                aria-label={
                                                                    this.state.searchType === "title" ? "Please input title" : "Please input creator"
                                                                }
                                                                aria-describedby="basic-addon2"
                                                                value={this.state.textareaValue}
                                                                onChange={(event) => {
                                                                    var valueString = event.target.value;
                                                                    var lines = valueString.split("\n").length;
                                                                    var textareaHeight = 200;
                                                                    if (lines < 6) {
                                                                        textareaHeight = (lines * 28 - (lines - 1) * 10)
                                                                    }
                                                                    this.setState({
                                                                        textareaValue: valueString,
                                                                        textareaHeight: textareaHeight
                                                                    })
                                                                }}
                                                            />
                                                            <Button size="sm" variant="outline-secondary" id="button-addon2" onClick={() => {
                                                                this.requestFindDonorById();
                                                            }}>
                                                                <BsSearch />
                                                            </Button>
                                                        </InputGroup>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            this.state.formData.donors && this.state.formData.donors.length > 0 ? (
                                                <div style={{ marginLeft: 10, marginRight: 10 }}>
                                                    <AiOutlineRightCircle style={{ color: "#009bdd" }} onClick={() => {
                                                        this.requestFindDonorById();
                                                    }} />
                                                </div>
                                            ) : null
                                        }
                                        {
                                            this.state.formData.donors && this.state.formData.donors.length > 0 ? (
                                                <div style={{ flex: 1 }}>
                                                    <div style={{ borderBottom: "1px solid #eee", fontSize: 13, textAlign: "center", lineHeight: "28px", height: 28 }}><span>Selected patient</span></div>
                                                    <div style={{ borderBottom: "1px solid #eee", display: "flex", lineHeight: "28px", height: 28 }}>
                                                        <div style={{ fontSize: 10, width: "33%", textAlign: "center" }}><span>Appuser ID</span></div>
                                                        <div style={{ fontSize: 10, width: "33%", textAlign: "center" }}><span>Patient ID</span></div>
                                                        <div style={{ fontSize: 10, width: "33%", textAlign: "center" }}><span>Name</span></div>
                                                    </div>
                                                    <div style={{ maxHeight: 142, overflowY: "scroll" }}>
                                                        {this.state.formData.donors && this.state.formData.donors.map((item, index) => {
                                                            return <div key={'donors' + index} style={{ borderBottom: "1px solid #eee", display: "flex", lineHeight: "21px", height: 21 }}>
                                                                <div style={{ fontSize: 10, width: "33%", textAlign: "center" }}>
                                                                    <span>{item.appuserId}</span>
                                                                </div>
                                                                <div style={{ fontSize: 10, width: "33%", textAlign: "center" }}>
                                                                    <span>{item.patientId ? item.patientId : `--`}</span>
                                                                </div>
                                                                <div style={{ fontSize: 10, width: "33%", textAlign: "center" }}>
                                                                    <span>{item.firstName + ' ' + item.lastName}</span>
                                                                    <AiOutlineCloseCircle style={{ marginLeft: 5, color: "#009bdd" }} onClick={() => {
                                                                        var formData = this.state.formData;
                                                                        formData.donors.splice(index, 1);
                                                                        this.setState({
                                                                            formData: formData
                                                                        })
                                                                    }} />
                                                                </div>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                    <div style={{ color: "#adadad", marginTop: 3, fontSize: 12 }}><b sytle={{ color: "#000" }}>
                                        Note&#8758; &nbsp;</b>To add multiple patients, please insert the Patient ID | App user ID in each row
                                    </div>
                                </div>
                            </div>
                        ) : null)}
                        <div className={"box-form mt-2 d-flex flex-column"}>
                            <Form.Check
                                type={'radio'}
                                checked={this.state.sendType === 'locations'}
                                label={'Select Location(s) to send'}
                                onChange={() => {}}
                                onClick={() => {
                                    this.setState({
                                        sendType: "locations"
                                    });
                                }}
                            />
                            {
                                this.state.sendType === "locations" && (
                                    <div className='mt-2'>
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            size={"large"}
                                            placeholder="Please select"
                                            value={this.state.selectedLocations}
                                            onChange={(value) => {
                                                this.setState({ selectedLocations: value });
                                            }}
                                            style={{ width: '100%', fontSize: '14px' }}
                                            options={this.state.allLocations}
                                        />
                                    </div>
                                )
                            }
                        </div>
                        {(false) && (
                            <div>
                                <div style={{ display: "flex", marginTop: 10 }}>
                                    <span style={{ marginTop: "auto", marginBottom: "auto" }}><span style={{ color: "red" }}>*</span>Select Center(s) to send∶ &nbsp;</span>
                                    <Button variant="outline-primary" type="button" className="btn btn-border-ouline btn-new-city btn-md" style={{ padding: "2px 10px", marginLeft: 20 }} onClick={() => { this.setState({ showRegionLevelModal: true }) }}>Select</Button>
                                </div>
                                {
                                    this.state.selectRegion && this.state.selectRegion.ids.length > 0 ? (
                                        <div className={"box-form mt-3"} style={{ display: "flex" }}>
                                            <div className="col-md-9" style={{ width: "100%", overflowY: "scroll", maxHeight: 72 }}>
                                                <div style={{ marginBottom: '1rem' }}>
                                                    {
                                                        this.state.selectRegion.ids.map((item, index) => {
                                                            return <div className={"vselected"} key={'fatimes' + index}>
                                                                {getCenterName(this.state.plasmaData, item)}
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        this.state.selectRegion && this.state.selectRegion.selectAll === true ? (
                                            <div className={"box-form mt-3"} style={{ display: "flex" }}>
                                                <div className="col-md-9" style={{ width: "100%", overflowY: "scroll", maxHeight: 72 }}>
                                                    <div style={{ marginBottom: '1rem' }}>
                                                        {
                                                            this.state.plasmaData.map((item, index) => {
                                                                return <div className={"vselected"} key={'fatimes' + index}>
                                                                    {item.name}
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                    )
                                }
                            </div>
                        )}
                        <div className={"box-form"} style={{ margin: 40 }}>
                            <div className={"form-input"}>
                                <div style={{ display: "flex", marginTop: 10 }}>
                                    <Button
                                        style={{ "margin": "auto", width: "30%", paddingTop: 5, paddingBottom: 5 }}
                                        disabled={
                                            getReviewStatus(this.state.title, this.state.message, this.state.sendType, this.state.formData.donors, this.state.selectRegion.ids, this.state.selectRegion.selectAll, this.state.selectedLocations)
                                        }
                                        className={"btn btn-md btn-blue"}
                                        onClick={() => this.setState({ showReviewModal: true })}
                                    >Review</Button>
                                    <Button
                                        style={{ "margin": "auto", width: "30%", paddingTop: 5, paddingBottom: 5 }}
                                        disabled={
                                            getReviewStatus(this.state.title, this.state.message, this.state.sendType, this.state.formData.donors, this.state.selectRegion.ids, this.state.selectRegion.selectAll, this.state.selectedLocations)
                                        }
                                        className={"btn btn-md btn-blue"}
                                        onClick={this.handleReviewModalSubmit}
                                    >Send</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NotificationModal
                    sendType={this.state.sendType}
                    title={this.state.title}
                    message={this.state.message}
                    selectRegion={this.state.selectRegion}
                    centers={this.state.plasmaData}
                    formData={this.state.formData}
                    selectedLocations={this.state.selectedLocations}
                    showReviewModal={this.state.showReviewModal}
                    handleReviewModalClose={() => this.setState({ showReviewModal: false, showModal: false })}
                    handleReviewModalSubmit={this.handleReviewModalSubmit}
                />

                <RegionLevelModal
                    selectRegion={this.state.selectRegion}
                    show={this.state.showRegionLevelModal}
                    onClose={() => { this.setState({ showRegionLevelModal: false }) }}
                    onConfirm={this.handleRegionLevelModalConfirm}
                />
            </div>
        )
    }
}

export default WithBio(NewNotification);
